import React from 'react';
import { Outlet } from 'react-router-dom';
import './styles.css';

const Atividades: React.FC = () => {
  return (
    <div style={{ paddingTop: '16px',}}>
      <Outlet />
    </div>
  );
};

export default Atividades;
