// LoginPage.tsx
import React, { useState, useEffect } from "react";
import { useAuthContext } from "../Auth/useAuthContext";
import { useNavigate, Link } from "react-router-dom";
import api from "../Services/axios";
import { getItem, setItem } from "../utils/localStorageAvailable";
import InputText from "../components/Input/InputText";
import { BallTriangle } from "react-loader-spinner";

const LoginPage: React.FC = () => {
  const { login, showLoading, hideLoading } = useAuthContext();

  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [logado, setLogado] = useState<boolean>(false);
  const [logando, setLogando] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const handleAvancaLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLogando(true);
      setMessage("efetuando login...");
      await login(email, password);
      setMessage("Login efetuado com sucesso!");
      console.log("Login efetuado com sucesso!");
      setLogando(false);
      navigate("/Empresa/Atividades");
    } catch (error) {
      setMessage(`Usuário ou senha incorretos!`);
      hideLoading();
      setLogando(false);
      console.error("Erro de Login: ", error);
    }
  };

  useEffect(() => {
    const savedEmail = getItem<string>("email");
    const savedPassword = getItem<string>("password");

    if (savedEmail) setEmail(savedEmail);
    if (savedPassword) setPassword(savedPassword);
  }, []);

  return (
    <div style={{display:'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'}}>
      <form className="container" onSubmit={handleAvancaLogin} style={{backgroundColor:'#ffffff', display:'flex', alignItems: 'center', justifyContent: 'center'}}>
        <section>
          <div className="corpologin flex" >

            <div className="containerlogincentral">

              <div>
                <div className="text1">Seja bem vindo à Sllips!</div>
                <label className="text2">
                  <br />
                  Digite seu email e senha para efetuar login caso já tenha
                  efetuado o cadastro!
                </label>
              </div>

              <InputText
                id="email"
                type="email"
                title="Email"
                placeholder="Digite seu Email"
                value={email}
                onchange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
                msnWarning="Informe seu email."
                required={true}
                autocomplete="email"
              />

              <InputText
                id="password"
                type="password"
                title="Senha"
                placeholder="Digite sua senha"
                value={password}
                onchange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
                msnWarning="Informe sua senha."
                required={true}
                autocomplete="current-password"
              />

              {logado && <div className="text3">Usuário ou senha incorreto!</div>}
              {message && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  {message}{" "}
                </div>
              )}
              {logando && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <BallTriangle color="#00bad1" />
                </div>
              )}

              {!logando && (
                <button style={{backgroundColor: "#00BFFF"}} className="btncadastrar" type="submit">
                  ENTRAR
                </button>
              )}

            </div>

          </div>

        </section>
      
      </form>
    </div>
  );
};

export default LoginPage;
