import FaixaetariaIdade  from "../componentes-dash/faixaetariaIdade";
import HistoricoVisitas from "../componentes-dash/historicoVisitas";
import PerfilGenero from "../componentes-dash/perfilGenero";
import Cidades from "../componentes-dash/estados";
import Estados from "../componentes-dash/cidades"
import { IoMailOutline, IoPrintOutline, IoFileTrayOutline } from 'react-icons/io5';
import CadastrosAteHoje from "../componentes-dash/cadastrosatehoje";
import VisitantesLocais from "../componentes-dash/visitanteslocais";
import VisitantesBrasileiro from "../componentes-dash/visitantesbrasileiros";
import VisitantesEstrangeiros from "../componentes-dash/visitantesestrangeiros";
import TotalRegistros from "../componentes-dash/totalregistros";
import { useAuthContext } from "../../../Auth/useAuthContext";
import DuracaoMediaEstadia from "../componentes-dash/duracaomediaestadia";
import MediaPasseios from "../componentes-dash/mediadepasseios";
import DadosExcursao from "../componentes-dash/dadosdeexcursao";
import ControleNautico from "../componentes-dash/controlepasseionautico";
import PontosEmbarque from "../componentes-dash/pontosemquarque";
import VeiculosAtividades from "../componentes-dash/veiculosporatividades";
import { DashbooardEmpresa } from "../empresa/DashboardEmpresa";
import { DashbooardPrefeitura } from "../prefeitura/DashboardPrefeitura";
import { DateRangePicker } from "rsuite";
import { useState } from "react";


export const Visitantes: React.FC = () => {
    const { user } = useAuthContext();
    const role = user?.segmento;
    const [dates, setDates] = useState<Array<Date>>([new Date('2024-11-01'), new Date()])

    return (    
        <div className="content content-fixed" style={{maxWidth: "80%", margin: "auto"}}>
            <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                            <li className="breadcrumb-item"><a href="/visitantes">Dashboard</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Perfil do visitante</li>
                        </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Bem vindo ao painel de controle Sllips</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-white btn-uppercase">
                        <IoMailOutline className="wd-10 mg-r-5" /> Email
                        </button>
                        <button className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                        <IoPrintOutline className="wd-10 mg-r-5" /> Print
                        </button>
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                        <IoFileTrayOutline className="wd-10 mg-r-5" /> Generate Report
                        </button>
                    </div>
                    
                </div>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '2rem'}}>
                        <DateRangePicker  
                            onChange={(value) => 
                            setDates(value || [])} 
                            onClean={ e => setDates([new Date('2024-11-01'), new Date()])}
                        />
                    </div>
                {
                    role === "Prefeitura" ?
                    (
                        <>
                            <DashbooardPrefeitura dates={dates}/>  
                        </>
                    )
                    :
                    (
                        
                        <>
                            <DashbooardEmpresa dates={dates}/>
                        </>
                    )
                }
            </div>
        </div>
            
    )
}