import api from '../../Services/axios';
import React, { useEffect, useState, useRef } from 'react';
import { FaUser } from 'react-icons/fa';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
// import QRCodeCanvas   from "qrcode.react";

const QrCodeList: React.FC = () => {

    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false)
    const elementoCapturado = useRef<any>({});

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await api.get('/lista-qrcodes', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }});
            setData(response.data);
            setLoading(false);
        }
        fetchData()
    },[])

    const gerarEDownloadPDF = async (id:any, chegada:any) => {
      console.log("Chegou aqui");
      const elemento = elementoCapturado.current;
      const doc = new jsPDF();
  
      const dataHoraAtual = new Date().toLocaleString("pt-BR", {
        timeZone: "America/Sao_Paulo",
        dateStyle: "short",
        timeStyle: "short",
      });

      console.log("Chegou aqui 2");
  
      const dataHoraFormatada = dataHoraAtual.replace(",", "_");
  
      const nomeArquivo =
        "Viagem_ID_" + id + "_Data_" + chegada + "_" + dataHoraFormatada;
  
      try {
        console.log("Chegou aqui 3");
        // Captura o elemento como uma imagem em formato PNG
        const canvas = await html2canvas(elemento);
        console.log("Chegou aqui 4");
        const imagemPNG = canvas.toDataURL("image/png");
  
        var imgWidth = 150;
        var imgHeight = 297;
  
        // Adiciona a imagem ao documento PDF
        doc.addImage(imagemPNG, 30, 0, imgWidth, imgHeight);
  
        // Obtém o texto do elemento capturado
        const textoElemento = elemento.innerText.trim();
  
        const nomeArquivoPDF = nomeArquivo.replace(/[^a-zA-Z0-9]/g, "") + ".pdf";
        console.log("Chegou aqui 5");
        doc.save(nomeArquivoPDF);
  
        const nomeArquivoPNG = nomeArquivo.replace(/[^a-zA-Z0-9]/g, "") + ".png";
  
        const blobPNG = await fetch(imagemPNG).then((res) => res.blob());
  
        // Crie um objeto FormData e anexe o Blob a ele para upload
        const formDataPNG = new FormData();
        formDataPNG.append("arquivo", blobPNG, nomeArquivoPNG);
  
        const responsePNG = await api.post("/upload", formDataPNG, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
  
        // Se o envio do arquivo for bem-sucedido, envie a mensagem com o URL do arquivo
  
        const urlArquivo = "https://sllips.com.br/api/uploads/" + nomeArquivoPNG; // Substitua 'url' pelo nome do campo que contém a URL do arquivo no objeto de resposta
  
        // Envio da mensagem com o URL do arquivo
  
      //   try {
      //     const responseMensagem = await api.post("/sendmensagemarq", {
      //       telefone: `${dadosCadastro.telefone}`,
  
      //       mensagem: "Segue o QRCdode Sllips.com.br",
  
      //       urlArq: urlArquivo,
      //     });
      //   } catch (error) {
      //     //console.error('Erro ao gerar e enviar o arquivo:', error);
      //   }
  
      // Se o envio do PDF for bem-sucedido, faça o download do PDF
      // Este passo dependerá do método de download específico usado em seu aplicativo
      } catch (error) {
        console.error("Erro ao gerar e enviar o arquivo:", error);
      }
    };
    

    if(loading){
        return <>Carregando...</>
    }

  return (
    <div style={{ marginTop: 20, flex: 1 }}>
      {
        data != null && data.length > 0 ? 
            (
                data.map((item: any) => (
                    <>
                        <div
                        key={item.id}
                        style={{
                            borderBottom: '1px solid #c7c7c7',
                            flexDirection: 'row',
                            marginBottom: '3%',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            /*gap:'9px',*/
                        }}
                        ref={elementoCapturado}
                        
                        >
                        <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', borderRadius: '5rem', padding: '2rem', backgroundColor: '#222222' }}>
                            <FaUser size={50}  style={styles.icon} />
                        </div>
                        <div style={{ flex: 4, marginLeft: '4%', paddingBottom: 3,  width:'27rem'}}>
                            <p style={{  fontFamily: 'Poppins-Regular', color: '#222222', fontSize: 13, textTransform: 'capitalize' }}>
                            {item.nome} {item.sobrenome}
                            </p>
                            <p style={{fontFamily: 'Poppins-light', color: '#6D6D6D', fontSize: 11 }}>
                                {item.Visitante.nome}
                                <br/>
                                {item.Visitante.identificacao}
                                {item.Visitante.passaporte}
                            </p>
                            <p style={{ fontFamily: 'Poppins-Regular', color: '#C13515', fontSize: 11 }}>Passageiro</p>
                        </div>

                        <div style={{padding: '10px'}}>
                        <div>
                         {/*  <QRCodeCanvas
                            value={item.id}
                            size={172}
                            bgColor={"#ffffff"}
                            fgColor={"#222222"}
                            level={"L"}
                          />
                          */}
                        </div>

                            <div style={{display: "flex", flex: 2, alignItems: "center", justifyContent: "center"}}>
                                <div  style={{
                                                borderRadius: 7,
                                                height: 30, 
                                                width: 95, 
                                                display: "flex",             // Adicionado para centralizar o conteúdo
                                                alignItems: "center",         // Alinha verticalmente o conteúdo
                                                justifyContent: "center",      // Alinha horizontalmente o conteúdo
                                                backgroundColor: '#222222' ,
                                                cursor: "pointer",

                                }} 
                                onClick={()=> gerarEDownloadPDF(item.id, item.data_inicio)}
                                >

                                    <span style={{fontFamily: 'Poppins-Regular', color: '#fff', fontSize: 12 }}>Baixar QRCode</span>

                                </div>
                            </div>
                            </div>
                        </div>
                    </>
                ))
            )
            :
            (
                <><p style={{color: "blue"}} >Não foram encontrados passageiros. Vá até QRCode para adicionar passageiros!</p></>
            )
      }
    </div>
  );
};


const styles = {
   
    icon: {
    
      color: '#ffffff',
    },
}

export default QrCodeList;
