// src/pages/Dashboard.tsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaSearch, FaBell , FaTimes    } from 'react-icons/fa';
import '../../css/dashforge.css';
import { useAuthContext } from '../../Auth/useAuthContext';
import Atividade_Novo from "../../assets/image/atividade_novo.svg";
import QRCode from "../../assets/image/qrcode.svg";
import Admin from "../../assets/image/admin.svg";
import Perfil from "../../assets/image/usuario.svg";
import Dashboard from '../../assets/image/dashb.svg';

interface Tab {
  label: string;
  icon: JSX.Element;
  path: string; // Adiciona o caminho da rota
}

const Header: React.FC = () => {
  const { user } = useAuthContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const navigate = useNavigate(); 

  const handleTabClick = (index: number, path: string) => {
    setActiveTab(index);
    navigate(path); 
  };

  const tabsAtendente: Tab[] = [
    { label: 'Atividade', icon: <img src={Atividade_Novo}  alt="Atividade"/>, path: '/Empresa/Atividades' },
    { label: 'QR Code', icon: <img src={QRCode} alt="QR Code" />, path: '/Empresa/QRCode' },
    { label: 'Admin', icon: <img src={Admin} alt="Administrador" />, path: '/Empresa/Admin' },
    { label: 'Perfil', icon: <img src={Perfil} alt="Usuário" />, path: '/Empresa/Perfil' },
  ];

  const tabsEmpresa: Tab[] = [
    { label: 'Atividade', icon: <img src={Atividade_Novo}  alt="Atividade"/>, path: '/Empresa/Atividades' },
    { label: 'Admin', icon: <img src={Admin} alt="Administrador" />, path: '/Empresa/Admin' },
    { label: 'Perfil', icon: <img src={Perfil} alt="Usuário" />, path: '/Empresa/Perfil' },
    { label: 'Dashboard', icon: <img src={Dashboard} alt="Dashboard" />, path: '/visitantes' },
  ];

  const tabsColaborador: Tab[] = [
    { label: 'Atividade', icon: <img src={Atividade_Novo}  alt="Atividade"/>, path: '/Empresa/Atividades' },
    { label: 'QR Code', icon: <img src={QRCode} alt="Usuário" />, path: '/Empresa/Qrcode' },
    { label: 'Perfil', icon: <img src={Perfil} alt="Usuário" />, path: '/Empresa/Perfil' },
  ];

  const tabsPrefeitura: Tab[] = [
    { label: 'Atividade', icon: <img src={Atividade_Novo}  alt="Atividade"/>, path: '/Empresa/Atividades' },
    { label: 'Perfil', icon: <img src={Perfil} alt="Usuário" />, path: '/Empresa/Perfil' },
    { label: 'Dashboard', icon: <img src={Dashboard} alt="Dashboard" />, path: '/visitantes' },
  ];

  const [isMobile, setIsMobile] = useState(false);

  // Verifica se a tela é pequena
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Checar no carregamento inicial
    window.addEventListener("resize", handleResize); // Listener para mudanças de tamanho

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ width: '100%', padding: '15px',borderBottom: '1px solid #e5e8eb',}}>
      <header 
        style={{ 
          display: 'flex', 
          width: '100%', 
          justifyContent: 'space-between', 
          
          alignItems: 'center',
        }}>

        <div className="navbar-brand">
          <Link to="/" className="df-logo">sllips<span>dashboard</span></Link>
        </div>

        <div style={{display: 'flex', justifyContent: 'space-between', width: '30%', }}>
        {
          isMobile  ? (
            <>
            </>
          )
          :
          (
            <>
              {
                (user?.segmento === "Marinha" || user?.segmento === "Colaborador") && (
                  <>
                    {tabsColaborador.map((tab, index) => (
                      <div
                        key={index}
                        className={`tab ${activeTab === index ? 'active' : ''}`}
                        onClick={() => handleTabClick(index, tab.path)} 
                      >
                        {tab.icon}
                        <span>{tab.label}</span>
                      </div>
                    ))}
                  </>
                )
              }
              {
                (user?.segmento === "Atendente") && (
                  <>
                    {tabsAtendente.map((tab, index) => (
                      <div
                        key={index}
                        className={`tab ${activeTab === index ? 'active' : ''}`}
                        onClick={() => handleTabClick(index, tab.path)} 
                      >
                        {tab.icon}
                        <span>{tab.label}</span>
                      </div>
                    ))}
                  </>
                )
              }
              {
                (user?.segmento === "Empresa") && (
                  <>
                    {tabsEmpresa.map((tab, index) => (
                      <div
                        key={index}
                        className={`tab ${activeTab === index ? 'active' : ''}`}
                        onClick={() => handleTabClick(index, tab.path)} 
                      >
                        {tab.icon}
                        <span>{tab.label}</span>
                      </div>
                ))
              }
            </>
          )
        }
            </>
          )
        }
        

        {
          user?.segmento === 'Prefeitura' && (
            <>
              {tabsPrefeitura.map((tab, index) => (
                <div
                  key={index}
                  className={`tab ${activeTab === index ? 'active' : ''}`}
                  onClick={() => handleTabClick(index, tab.path)} 
                >
                  {tab.icon}
                  <span>{tab.label}</span>
                </div>
              ))}
            </>
          )
        }
        </div>

        <div style={{display: 'flex', alignItems: 'center'}}>
          
        </div>
      </header>
    </div>
  );
};

export default Header;
