import Box from '@mui/material/Box';
import { BarChart } from '@mui/x-charts/BarChart';
import { useState, useEffect } from 'react';
import { Button, ButtonGroup, ButtonToolbar, Checkbox as RCheckbox } from 'rsuite';
import { differenceInYears, getMonth } from 'date-fns';

interface Series {
    label: string;
    data: number[]
}

interface Options {
    groups: string[],
    interval: string,
}

const allValues = ['Homens', 'Mulheres', 'Crianças', 'Outros'];
let anosDisponiveis: string[] = []

export default function BarAnimation({ data }:any) {
    const [newSeries, setNewSeries] = useState<Series[]>([{label: '', data:[]}]);
    const [options, setOptions] = useState<Options>({groups: allValues, interval: 'semanal'});
    const [skipAnimation, setSkipAnimation] = useState(false);


    function filtraDadosMensal(dados:any){
        const { visitantes, acompanhantes } = dados;

        // Combina visitantes e acompanhantes em uma lista
        const pessoas = [...visitantes, ...acompanhantes];

        // Inicializa a lista de contagem mensal para cada categoria
        const resultado = [
            { label: 'Homens', data: Array(12).fill(0) },
            { label: 'Mulheres', data: Array(12).fill(0) },
            { label: 'Crianças', data: Array(12).fill(0) },
            { label: 'Outros', data: Array(12).fill(0) },
        ];

        pessoas.forEach((pessoa) => {
            const dataNascimento = pessoa.data_nascimento;
            const createdAt = pessoa.createdAt;
            const mes = getMonth(createdAt); // Extrai o índice do mês (0-11)

            // Identifica a categoria da pessoa
            if (pessoa.sexo === 'Masculino') {
                resultado[0].data[mes]++;
            } else if (pessoa.sexo === 'Feminino') {
                resultado[1].data[mes]++;
            } else if (pessoa.sexo === 'Outros') {
                resultado[3].data[mes]++;
            }

            // Verifica se a pessoa é uma criança (menor de 14 anos)
            const idade = differenceInYears(new Date(), dataNascimento);
            if (idade < 14) {
                resultado[2].data[mes]++;
            }
        });

        setNewSeries(resultado);
    }

    function filtraDadosSemanal(dados: any){
        const { visitantes, acompanhantes } = dados;
        const pessoas = [...visitantes, ...acompanhantes];

        const categorias = {
            Homens: [0, 0, 0, 0, 0, 0, 0],
            Mulheres: [0, 0, 0, 0, 0, 0, 0],
            Crianças: [0, 0, 0, 0, 0, 0, 0],
            Outros: [0, 0, 0, 0, 0, 0, 0]
        };
    
        pessoas.forEach((pessoa) => {
            const dataChegada = new Date(pessoa.Viagem?.data_chegada);
            const diaDaSemana = dataChegada.getDay();

            // Contagem por categoria de idade (Crianças: menor de 14 anos)
            const idade = new Date().getFullYear() - new Date(pessoa.data_nascimento).getFullYear();
            if (idade < 14) {
                categorias.Crianças[diaDaSemana] += 1;
                return;
            }
        
            // Contagem por categoria de sexo
            if (pessoa.sexo === 'Masculino') {
                categorias.Homens[diaDaSemana] += 1;
            } else if (pessoa.sexo === 'Feminino') {
                categorias.Mulheres[diaDaSemana] += 1;
            } else if (pessoa.sexo === 'Outros') {
                categorias.Outros[diaDaSemana] += 1;
            }    
        });
        
        // Criando o array com as categorias
        const resultado = [
            { label: 'Homens', data: categorias.Homens },
            { label: 'Mulheres', data: categorias.Mulheres },
            { label: 'Crianças', data: categorias.Crianças },
            { label: 'Outros', data: categorias.Outros }
        ];
        
        setNewSeries(resultado);
    }

    function filtraDadosAnual(dados: any){
        const { visitantes, acompanhantes } = dados;
        const pessoas = [...visitantes, ...acompanhantes];

        const anos: any = {};

        // Processa os dados
        pessoas.forEach((pessoa) => {
            const viagem = pessoa.Viagem;
            if (!viagem || !viagem.data_chegada) return; 

            // Extrai o ano da data de chegada
            const anoChegada = new Date(viagem.data_chegada).getFullYear();

            // Determina o grupo de acordo com o sexo e a idade
            const idade = new Date().getFullYear() - new Date(pessoa.data_nascimento).getFullYear();
            let categoria = '';

            // Define as categorias com base na idade e sexo
            if (idade < 14) {
                categoria = 'Crianças';
            } else if (pessoa.sexo === 'Masculino') {
                categoria = 'Homens';
            } else if (pessoa.sexo === 'Feminino') {
                categoria = 'Mulheres';
            } else if (pessoa.sexo === 'Outros') {
                categoria = 'Outros';
            }

            // Se o ano não existe no objeto, inicialize com todas as categorias com valor 0
            if (!anos[anoChegada]) {
                anos[anoChegada] = {
                    Homens: 0,    
                    Mulheres: 0,  
                    Crianças: 0,  
                    Outros: 0     
                };
            }

            // Conta a pessoa no ano correto dentro de sua categoria
            anos[anoChegada][categoria]++;
        });

        // Agora, cria os resultados para cada categoria (Homens, Mulheres, Crianças, Outros) por ano
        const categorias = ['Homens', 'Mulheres', 'Crianças', 'Outros'];

        const resultados = categorias.map((categoria) => {
            const data = Object.keys(anos).map((ano) => anos[ano][categoria]);
            return {
                label: categoria,
                data: data
            };
        });

        Object.keys(anos).forEach((item) => {
            anosDisponiveis.push(item);
        });

        setNewSeries(resultados);
    }

    function HandleOptionChange(value: any, checked: boolean, event: React.ChangeEvent<HTMLInputElement>) {
        checked = event.target.checked;
    
        setOptions((prev) => {
          if (value === 'all') {
            // Se "Todos" for clicado, seleciona ou desmarca todos
            return {
              ...prev,
              groups: checked ? allValues : [], // Seleciona todos ou nenhum
            };
          }
    
          // Caso contrário, adiciona ou remove o valor individualmente
          const updatedGroups = checked
            ? [...prev.groups, value]
            : prev.groups.filter((v) => v !== value);
    
          // Verifica se todos os checkboxes estão marcados
          const isAllChecked = allValues.every((v) => updatedGroups.includes(v));
    
          return {
            ...prev,
            groups: isAllChecked ? allValues : updatedGroups, // Atualiza a lista de grupos
          }
        });
    };

    function handleIntervalOptionChange(event: React.MouseEvent<HTMLElement, MouseEvent>){
        const selectedInterval = event.currentTarget.textContent?.toLowerCase(); // Converte o texto do botão para minúsculas
        setOptions(prevOptions => ({
          ...prevOptions,
          interval: selectedInterval || ''
        }));
    }

    useEffect(() => {
        if(options.interval === 'mensal'){
            filtraDadosMensal(data);
        }

        if(options.interval === 'semanal'){
            filtraDadosSemanal(data);
        }

        if(options.interval === 'anual'){
            filtraDadosAnual(data);
        }
        
    }, [options])
  
  
    return (
        
      <Box sx={{ width: '81%', border: '1px solid #e5e8eb', borderRadius: '10px', padding: '2rem' }}>
        <div style={{display: 'flex', gap: '5rem', justifyContent: 'space-between', alignItems:'start'}}>

            <ButtonToolbar>
                <ButtonGroup>
                    <Button appearance={options.interval === 'semanal' ? 'primary': 'default'} onClick={(e) => handleIntervalOptionChange(e)}>Semanal</Button>
                    <Button appearance={options.interval === 'mensal' ? 'primary': 'default'} onClick={(e) => handleIntervalOptionChange(e)}>Mensal</Button>
                    <Button appearance={options.interval === 'anual' ? 'primary': 'default'} onClick={(e) => handleIntervalOptionChange(e)}>Anual</Button>
                </ButtonGroup>
            </ButtonToolbar>

            <div>
                <RCheckbox
                    value="all"
                    checked={options.groups.length === allValues.length}
                    onChange={(value, checked, e) => HandleOptionChange('all', checked, e)}
                >
                    Todos
                </RCheckbox>
                {allValues.map((value) => (
                    <RCheckbox
                    key={value}
                    value={value}
                    checked={options.groups.includes(value)} // Verifica se o valor está incluído
                    onChange={(value, checked, event) => HandleOptionChange(value, checked, event)}
                    >
                    { value }
                    </RCheckbox>
                ))}
            </div>
        </div>

        <BarChart
            height={300}
            borderRadius={5}
            xAxis={[
                {
                    scaleType: 'band',
                    data: options.interval === "mensal" ? 
                        ['Jan', 'Fev', 'Mar', 'Abri', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'] : 
                    options.interval === "semanal" ? 
                        ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'] :
                    options.interval === "anual" ? 
                        anosDisponiveis : []
                },
            ]}
            series={
                newSeries.filter((s) => options.groups.includes(s.label)) 
            }
            skipAnimation={skipAnimation}
            barLabel="value"
        />
      </Box>
    );
}

export const DashGenero = ({data}:any) => {
    return (
        <>
            <BarAnimation data={data}/>
        </>
    )
}