import React, { useState, useEffect } from "react";
import QrScanner from "react-qr-scanner";
import api from "../../Services/axios";
import InputText from "../Input/InputText";
import { FaQrcode } from "react-icons/fa";
import CheckBoxList from "../List/CheckBoxList";
import { useAuthContext } from "../../Auth/useAuthContext";
import { useNavigate } from "react-router-dom";

interface Visitante {
  id: number;
  nome: string;
  senha: string;
  sobrenome: string;
  tipoIdentificacao: string;
  identificacao: string;
  sexo: string;
  data_nascimento: string;
  escolaridade: string;
  compartilha_dados: boolean | null;
  cidade: string;
  cidade_id: number | null;
  email: string;
  telefone: string;
  nacionalidade: string | null;
  cnpjEmpresa: string | null;
  veiculoEmpresa: string | null;
  cnpjComercial: string | null;
  nomeEmpresa: string | null;
  createdAt: string;
  updatedAt: string;
}

interface Viagem {
  id: number;
  visitante_id: number;
  municipio_id: number;
  tipo_turismo: string;
  nome_empresa: string | null;
  viajando_com: string;
  data_chegada: string;
  data_partida: string;
  conhece_municipio: boolean;
  acomodacao_tipo: string;
  acomodacao_nome: string;
  transporte_tipo: string;
  transporte_placa: string;
  emergencia_nome: string;
  emergencia_parentesco: string;
  emergencia_telefone: string;
  createdAt: string;
  updatedAt: string;
  Visitante: Visitante;
  acompanhante: Acompanhante[];
}

interface Acompanhante {
  id: number;
  nome: string;
  sobrenome: string;
  passaporte: string;
  cpf: string;
  sexo: string;
  data_nascimento: string;
  nacionalidade: string;
  cidade: string | null;
  cidade_id: number | null;
  createdAt: string;
  updatedAt: string;
  viagem_id: number;
}

interface DadosViagem {
  viagem: Viagem[];
}

const QRCodeReader: React.FC = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const [data, setData] = useState<string | null>(null);
  const [idViagem, setIdViagem] = useState<number>();
  const [identificacao, setIdentificacao] = useState<string | any>();
  const [passaporte, setPassaporte] = useState<string>('');
  const [mensagem, setMensagem] = useState<string | null>("");
  const [showScanner, setShowScanner] = useState<boolean>(false);
  const [cameraError, setCameraError] = useState<string | null>(null);
  const [tipoBusca, setTipoBusca] = useState<string>('viagem');
  const [listaSelector, setListaSelector] = useState<Acompanhante[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dados, setDados] = useState<DadosViagem | null>(null);

  const handleScan = (result: any) => {
    if (result && typeof result === "object" && result.text) {
      setData(result.text);
      buscaDadosViagam(result.text);
      toggleScanner();

    }
  };

  const handleTipoBuscaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTipoBusca(e.target.value);
    setListaSelector([]);
  }

  const limparIdentificacao = async (id: string) => {
    return id.replace(/[.\-\/]/g, "");
  };

  useEffect(() => {
    const fetchViagem = async () => {
      if (identificacao && identificacao.length === 11) {
        try {
          const idLimpo = await limparIdentificacao(identificacao);

          if(tipoBusca === 'viagem'){
            const {data: res} = await api.get(`/viagembycpf/${idLimpo}`);

            if (res.message) {
              setMensagem(res.message);
            }
  
            // ! Mais de uma viagem com a mesma indentificacao????
            setIdViagem(res.viagem[0].id);
            
            return;
          }else{
            const {data: res} =  await api.get(`/pessoabycpf/${idLimpo}`);
            buscaDadosPessoa(res)
            return;
          }
        } catch (error) {
          console.error("Erro ao buscar viagem: ", error);
        }
      } else if(passaporte && passaporte.length > 5){
        try {

         if(tipoBusca === 'viagem'){
            const {data: res} = await api.get(`/viagembycpf/${passaporte}`);

            if (res.message) {
              setMensagem(res.message);
            }
  
            // ! Mais de uma viagem com a mesma indentificacao????
            setIdViagem(res.viagem[0].id);
            return;
          }else{
            const {data: res} =  await api.get(`/pessoabycpf/${passaporte}`);
            buscaDadosPessoa(res)
            return;
          }
        } catch (error) {
          console.error("Erro ao buscar viagem: ", error);
        }
        setMensagem("");
      }
    };

    fetchViagem();
  }, [identificacao, tipoBusca, passaporte]);

  useEffect(() => {
    buscaDadosViagam(idViagem ?? 0);
  }, [idViagem, tipoBusca]);

  const buscaDadosViagam = async (idViagem: number) => {
    if (idViagem) {
      try {
        const { data: response } = await api.get(`/viagemNew/${idViagem}`);
        
        if (response) {
          
          setDados(response.viagem);

          let acompanhantes = response.viagem.Acompanhantes;

          acompanhantes = acompanhantes.sort((a:any, b:any) => {
            if (!a.nome) return 1; // `a.nome` ausente, coloca no final
            if (!b.nome) return -1; // `b.nome` ausente, coloca no início
            return a.nome.localeCompare(b.nome);
          })

          const visitante = {
            nome: response.viagem.Visitante.nome,
            sobrenome: response.viagem.Visitante.sobrenome,
            cpf: response.viagem.Visitante.identificacao ?? '',
            passaporte: response.viagem.Visitante.passaporte ?? '',
            nacionalidade: response.viagem.Visitante.nacionalidade,
            sexo: response.viagem.Visitante.sexo,
            data_nascimento: response.viagem.Visitante.data_nascimento,
            id: response.viagem.Visitante.id,
            cidade: response.viagem.Visitante.cidade, 
            cidade_id: response.viagem.Visitante.cidade_id, 
            createdAt: response.viagem.Visitante.createdAt, 
            updatedAt: response.viagem.Visitante.updatedAt, 
            viagem_id: response.viagem.Visitante.viagem_id
          };

          if (acompanhantes.length > 0) {
            setListaSelector([visitante, ...acompanhantes, ]);
            return;
          }

          setListaSelector([visitante]);
          
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error("Erro ao buscar viagem: ", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const buscaDadosPessoa = async (res: any) => {
    if (res) {
      try {
        setMensagem(res.message)
        const visitante = {
          nome: res.viagem.nome,
          sobrenome: res.viagem.sobrenome,
          cpf: res.viagem.identificacao || res.viagem.cpf || res.viagem.passaporte,
          passaporte: res.viagem.passaporte ?? '',
          nacionalidade: res.viagem.nacionalidade,
          sexo: res.viagem.sexo,
          data_nascimento: res.viagem.data_nascimento,
          id: res.viagem.id,
          cidade: res.viagem.cidade, 
          cidade_id: res.viagem.cidade_id, 
          createdAt: res.viagem.createdAt, 
          updatedAt: res.viagem.updatedAt, 
          viagem_id: res.viagem.viagem_id
        };

        setListaSelector([visitante]);
                  
      } catch (error) {
        console.error("Erro ao buscar viagem: ", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  function onCheckIn(lista: Acompanhante[]) {
    const aux: Acompanhante[] = lista.map((item) => ({
      viagem_id: item?.viagem_id,
      nome: item.nome,
      sobrenome: item.sobrenome,
      identificacao: item.cpf, // Assumindo que identificacao é o cpf
      cpf: item.cpf ?? '', // Adiciona a propriedade cpf aqui
      passaporte: item.passaporte ?? '',
      nacionalidade: item.nacionalidade,
      checkin: true,
      checkout: false,
      sexo: item.sexo,
      data_nascimento: item.data_nascimento,
      cidade: item.cidade,
      cidade_id: item.cidade_id, 
      createdAt: item.createdAt, 
      updatedAt: item.updatedAt,
      id: Date.now() + Math.floor(Math.random() * 1000),
    }));

    if (aux) {
      salvarCheckIn(aux);
    }
  }

  function onCheckOut(lista: Acompanhante[]){
    const aux: Acompanhante[] = lista.map((item) => ({
      viagem_id: item?.viagem_id,
      nome: item.nome,
      sobrenome: item.sobrenome,
      identificacao: item.cpf, // Assumindo que identificacao é o cpf
      cpf: item.cpf ?? '', // Adiciona a propriedade cpf aqui
      passaporte: item.passaporte ?? '',
      nacionalidade: item.nacionalidade,
      checkin: true,
      checkout: false,
      sexo: item.sexo,
      data_nascimento: item.data_nascimento,
      cidade: item.cidade,
      cidade_id: item.cidade_id, 
      createdAt: item.createdAt, 
      updatedAt: item.updatedAt,
      id: Date.now() + Math.floor(Math.random() * 1000),
    }));

    if (aux) {
      salvarRemovedCheckIn(aux);
    }
  }

  async function salvarCheckIn(lista: Acompanhante[]) {
    if (user) {
      try {
        const response = await api.post(`/atividade/addpassageiros`, {
          listaPassageiros: lista,
          colaborador: user.id,
        }, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }});
      } catch (error: any) {
        console.error(error);
      }
    }
  }

  async function salvarRemovedCheckIn(lista: Acompanhante[]) {
    if (user) {
      try {
        const response = await api.post(`/atividade/removepassageiros`, {
          listaPassageiros: lista,
          colaborador: user.id,
        }, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }});
      } catch (error: any) {
        console.error(error);
      }
    }
  }

  const handleError = (err: any) => {
    console.error(err);
    setCameraError(
      "Erro ao acessar a câmera. Verifique as permissões ou use outro dispositivo."
    );
  };

  const toggleScanner = () => {
    if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === "function") {
      setShowScanner((prevShowScanner) => !prevShowScanner);
      setCameraError(null);
    } else {
      setCameraError("Este dispositivo não suporta a funcionalidade de câmera.");
    }
  };

  // if (user?.segmento !== "Colaborador") {
  //   return <div style={{ textAlign: "center", color: "red" }}>Somente para Colaboradores</div>;
  // }

  if(!['Colaborador', 'Marinha', 'Atendente'].includes(user?.segmento?? '')){
    return <div style={{ textAlign: "center", color: "red" }}>Somente para Colaboradores</div>;
  }

  return (
    <div style={styles.container}>
      <p
        style={{
          textAlign: "center",
          paddingTop: "10px",
          fontSize: "20px",
          fontWeight: "bold",
          fontFamily: "Poppins-Regular",
          color:"#222222"
        }}
      >
        Vamos dar início às atividades!
      </p>

      <p style={{ textAlign: "center", paddingTop: "10px", fontFamily: "Poppins-Medium", fontSize:'14px', color:'#6a6a6a' }}>
        Alinhe o código QR dentro do quadro para digitalizar
      </p>

      {showScanner && !cameraError && (
        <div style={styles.button}>
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "100%" }}
            constraints={{ video:{facingMode: 'environment'} }}
          />
        </div>
      )}

      {cameraError && (
        <p
          style={{
            color: "red",
            textAlign: "center",
            fontFamily: "Poppins-Regular",
          }}
        >
          {cameraError}
        </p>
      )}

      <div
        style={{

          flexDirection: 'column',
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5%",
        }}
      >
        <div style={styles.button} onClick={toggleScanner}>
          <FaQrcode size={100} />
        </div>

        <div>
            <p
              style={{
                textAlign: "center",
                paddingTop: "10px",
                fontSize: "12px",
                fontFamily: "Poppins-Medium",
                color:"#008AC0"
              }}
            >
              Clique para Scanear
            </p>
          </div>
          
      </div>

      <div>
        <p
          style={{
            textAlign: "center",
            paddingTop: "10px",
            fontSize: "13px",
            fontFamily: "Poppins-Medium",
            color:"#222222"
          }}
        >
          Escaneie o QRCode ou busque pelo visitante ou pela viagem!
        </p>

        <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <div style={{display: 'flex'}}>
            <input defaultChecked id="viagemCheckbox" value='viagem' type="radio" name="busca" onChange={handleTipoBuscaChange}/> 
            <p style={{margin: 'auto 1rem'}}>Viagem</p>
          </div>
          <div style={{display: 'flex'}}>
            <input id="visitanteCheckbox" value='visitante' type="radio" name="busca" onChange={handleTipoBuscaChange}/> 
            <p style={{margin: 'auto 1rem'}}>Visitante</p>
          </div>
        </div>
        
        {
          tipoBusca === 'viagem' ?
          (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "5%",
                }}
              >
                <InputText
                  id="identificacao"
                  title=""
                  type="string"
                  placeholder="CPF/Passaporte do Visitante/Acompanhante"
                  value={identificacao}
                  onchange={(e: React.ChangeEvent<HTMLInputElement>) =>{
                    setIdentificacao(e.target.value)
                    setPassaporte(e.target.value)
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "5px",
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#c13515",
                  fontFamily: "Poppins-Regular",
                }}
              >
                {mensagem}
              </div>

              {listaSelector.length > 0 && (
                <CheckBoxList
                  multiple
                  options={listaSelector}
                  onchange={(op) => onCheckIn(op as Acompanhante[])}
                  onchangeRemove={(op) => onCheckOut(op as Acompanhante[])}
                />
              )}
            </>
          )
          :
          (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "5%",
                }}
              >
                <InputText
                  id="identificacao"
                  title=""
                  type="string"
                  placeholder="CPF do visitante"
                  value={identificacao}
                  onchange={(e: React.ChangeEvent<HTMLInputElement>) =>{
                    setIdentificacao(e.target.value)
                    setPassaporte(e.target.value)
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "5px",
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#c13515",
                  fontFamily: "Poppins-Regular",
                }}
              >
                {mensagem}
              </div>

              {listaSelector.length > 0 && (
                <CheckBoxList
                  multiple
                  options={listaSelector}
                  onchange={(op) => onCheckIn(op as Acompanhante[])}
                  onchangeRemove={(op) => onCheckOut(op as Acompanhante[])}
                />
              )}
            </>
          )
        }
      </div>
    </div>
  );
};

export default QRCodeReader;

const styles = {
  container: {
    width: "100% auto",
    flex: 1,
    backgroundColor: "#fff",
    padding: "5%",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    marginTop: "5%",
    cursor: "pointer",
    
    
  },
};
