import React from 'react';
import { Outlet } from 'react-router-dom';
import './styles.css';


const Notificacao: React.FC = () => {

  return (
    <div style={{ paddingTop: '20px' }}>

      <div>Conteúdo da Notificacao</div>

      <Outlet />

    </div>
  );
};

export default Notificacao;
