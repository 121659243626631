import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import html2pdf from 'html2pdf.js';
import { useAuthContext } from "../../Auth/useAuthContext";
import api from "../../Services/axios";
import {addHours, format, parseISO} from 'date-fns'
import { ptBR } from 'date-fns/locale';

interface Passageiro {
    nome: string;
    sobrenome: string;
    identificacao: string;
    passaporte: string;
    nacionalidade: string;
    emergencia_nome: string;
    emergencia_telefone: string;
    data_nascimento: string;
    cidade: string;
}

interface User {
    id: number;
    email: string;
    telefone: string;
}

interface Colaborador {
    nome: string;
    funcao: string;
    inscricao: string;
    email: string;
    telefone: string;
    cpf: string;
    habilitacao: string;
    habilitacao_marinheiro: string;
    User: User;
}

interface LocalEmbarque {
    id: number;
    prefeitura_id: number;
    nome: string;
    local: string;
}

interface LocalDesembarque {
    id: number;
    prefeitura_id: number;
    nome: string;
    local: string;
}

interface Veiculo {
    id: number;
    nome: string;
    identificacao: string;
    capacidade: string;
    local_embarque_id: number;
    empresa_id: number;
    pontoIncricao: string;
    tipoVeiculo: string;
    local_Desembarque_id: number;
    inscricao: string;
    modeloVeiculo: string;
    arquecaoBruta: string;
    estadoSelecionado: string | null;
    municipioSelecionado: string | null;
    destino: string;
    LocalEmbarque: LocalEmbarque;
    LocalDesembarque: LocalDesembarque;
}

interface Endereco {
    id: number;
    rua: string;
    numero: string;
    complemento: string;
    bairro: string;
}

interface User  {
    id: number;
    email: string;
}

interface Empresa {
    id: string;
    rasao_social: string;
    nicho_mercado: string;
    cnpj: string;
    telefone: string;
    capacidade_total: number;
    User: User;
    Endereco: Endereco;
}

interface Atividade {
  id: number;
  usuario_id: number;
  cidade_id: number | null;
  empresa_id: number;
  veiculo_id: number | null;
  ativa: number;
  rasao_social: string | null;
  duracao: string;
  passageiros: Passageiro[];
  Veiculo: Veiculo | null;
  Empresa: Empresa;
  atividade: string | null;
  capacidade_total: number | null;
  qtde_quartos: number | null;
  data_inicio: string; // ISO date string
  data_fim: string;
  createdAt: string; // ISO date string
  updatedAt: string; // ISO date string
}

interface Dados {
    atividade: Atividade;
    colaborador: Colaborador;
}

const TermoAtividades: React.FC = () => {
    const [dados1, setDados1] = useState<Dados | undefined>(undefined);
    const navigate = useNavigate();
    const { user, showLoading, hideLoading } = useAuthContext();

    const location = useLocation();

    const { idAtividade } = location.state as { idAtividade: string;};

    useEffect(() => {
        showLoading();
        
        return () => hideLoading();
    }, [showLoading, hideLoading]);
    let qtde_passageiros = useRef<number>();
    let passageiros = useRef<Passageiro[]>([])
    let fim_atividade = useRef<string | Date>('');

    useEffect(() => {
        (async () => {
            showLoading();
            try{   

                const { data: response } = await api.get(`/atividade/detalhe/${idAtividade}/${user?.id}`, 
                { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }})   

                setDados1(response);

                const duracao = parseInt(response.atividade.duracao.split(' ')[0], 10);

                fim_atividade.current = addHours(response.atividade.data_inicio, duracao);

                qtde_passageiros.current = JSON.parse(response.atividade.passageiros)?.length;
                passageiros.current = JSON.parse(response.atividade.passageiros)
                
                hideLoading();
            }
            catch(error){
                //setIsLoading(false);
                console.log("Caiu no erro: ", error)
                //navigation.goBack();
            };



        })();
    }, [location]) 

    async function gerarLista(passageiros: Passageiro[]): Promise<string> {
        if(passageiros?.length !== 0){
            return passageiros?.map((item, key) => `
                <tr style="height:15pt; text-align:center">
                    <td style="width:69pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                            <p style="text-indent: 0pt;text-align: center;">
                                ${key+1}
                                <br />
                            </p>
                    </td>
                    <td style=" width:69pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                        <p style="text-indent: 0pt;text-align: center;">
                            ${item.nome} ${item.sobrenome}
                            <br />
                        </p>
                    </td>
                    <td style="width:69pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                        <p style="text-indent: 0pt;text-align: center;">
                            ${(item.nacionalidade === null || item.nacionalidade === undefined ) ? '' : item.nacionalidade} 
                            <br />
                        </p>
                    </td>
                    <td style="width:69pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                        <p style="text-indent: 0pt;text-align: center;">
                            ${(item.cidade === null || item.cidade === undefined ) ? '' : item.cidade} 
                            <br />
                        </p>
                    </td>
                    <td style="width:69pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                        <p style="text-indent: 0pt;text-align: center;">
                           ${formatarDataParaPTBR(item.data_nascimento) ?? ''}
                            <br />
                        </p>
                    </td>
                    <td style="width:69pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                        <p style="text-indent: 0pt;text-align: center;">
                           ${item.identificacao ?? ''}
                           ${item.passaporte ?? ''}
                            <br />
                        </p>
                    </td>
                    </tr>
                    
            `).join("");
        }

        return '';
    }

    function formatarDataParaPTBR(dataISO: string): string {
        const data = new Date(dataISO);
        return data.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    // Função para enviar o PDF para a API
    async function enviarPDF(file: any) {
        const formData = new FormData();
        formData.append('arquivo', file);
        formData.append('empresa_id', String(dados1?.atividade.empresa_id ?? "defaultEmpresaId"));
    
        api.post('/envio/termos/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(response => console.log('PDF enviado com sucesso!'))
        .catch(error => console.error('Erro ao enviar PDF:', error));
    }
    

    async function createPDF(item: number): Promise<void> {
        if (!dados1) return;
    
        const lista = await gerarLista(passageiros.current);

        const isoDateInicio = parseISO(dados1.atividade.data_inicio)
        const dataInicioPTBR = format(isoDateInicio, 'dd/MM/yyyy HH:mm:ss', {locale: ptBR})
        const dataFimPTBR  = format(new Date(fim_atividade.current), 'dd/MM/yyyy HH:mm:ss', {locale: ptBR})
    
        const htmlContent = `
                <html lang="pt">
                    <head>
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                    <title>Anx-da-Port-n-26-2022-da-DelFurnas---Aviso-de-Saida.pdf</title>
                    <meta name="author" content="55379" />
                    <style type="text/css">
                    /*<![CDATA[*/
                    * {margin:0; padding:0; text-indent:0; }
                    .s1 { color: #C8211C; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12.5pt; }
                    .s2 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 14pt; }
                    .s3 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
                    .s4 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
                    .s5 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8.5pt; }
                    .s6 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8pt; }
                    .s7 { color: black; font-family:Cambria, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 7.5pt; }
                    .s8 { color: black; font-family:"Courier New", monospace; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8.5pt; }
                    .s9 { color: black; font-family:Cambria, serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8pt; }
                    p { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10.5pt; margin:0pt; }
                    .s10 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8.5pt; }
                    .s11 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10.5pt; }
                    .s12 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 1pt; }
                    table, tbody {vertical-align: top; overflow: visible; }
                    /*]]>*/


                    /* Centralizando o body */
                    .page-break {
                        page-break-before: always;
                    }
                    body {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 100vh; /* Faz o body ocupar 100% da altura da tela */
                        margin: 0; /* Remove a margem padrão */
                        font-family: Arial, sans-serif; /* Apenas para estética */
                        background-color: #f0f0f0; /* Cor de fundo */
                        }

                        /* Estilizando o conteúdo */
                        .content {
                        display: flex;
                        text-align: center;
                        padding: 20px;
                        background: #fff;
                        border-radius: 10px;
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                        flex-direction: column;
                        }

                        .footer {
                            margin-top: 50px;
                            font-size: 16px;
                            text-align: center;
                        }

                        .underline {
                            display: inline-block;
                            width: 200px;
                            border-bottom: 1px solid black;
                            margin: 0 5px;
                        }
                            .signature {
                            margin-top: 40px;
                            text-align: center;
                            font-size: 16px;
                        }

                        .signature-line {
                            display: inline-block;
                            width: 300px;
                            border-bottom: 2px solid black;
                            margin-top: 20px;
                        }
                    </style>
                    </head>
                    <body  class="content">
                    <p class="s1" style="padding-top: 4pt;padding-left: 19pt;text-indent: 0pt;text-align: center;">Este documento deverá ser encaminhado para “de1furnas.segmar nmarinha.mil.br” ou o pelo whatsapp : (31)98312-1339</p>
                    <p class="s2" style="text-indent: 0pt;line-height: 200%;text-align: center;">MARINHA DO BRASIL DELEGACIA FLUVIAL DE FURNAS</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <p style="padding-left: 5pt;text-indent: 0pt;text-align: left;"></p>
                    <table border="0" cellspacing="0" cellpadding="0">
                        <tr>
                        <td><img width="28" height="14" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAOCAYAAAA8E3wEAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACCklEQVQ4jW2Uv49NURSFv/UygxlEBBGJECQS0YipRTGNKBR0ErVGIUjE30AiIoKCSFQKjU4hREOhNlOgESQKqon5Ye6neOdw5roruXnnvL3X2vusc/eNekk9mWRWNQnAvLqYZJf6MckL9X6Sr6zFGeAIcBWYBFAXksyX+DZgb1kHmEal67rdqt0Yq+qMirql67rraqeuqLfVya7rKPH6vGr4b7quS9FF3a8+LhpTlbDdf1hVZxrRkfqyxDr1Ua8Y6sNKbgs2z3r1tTo1KjbQR7EWoFMvO04KcFY9NMSpvIZ7rHCWgKcAo0HW/yJzSX6U7SRwM8lgoz2cbNb3gV9/C9qwqxUNVoCfTd6MOt3Lt6exVT3f/LWoMlKrDX996NkCsAoslliSbEiyvpefJFH3AXeTvAE2tjpJGPWE16B3yr5/bY+tQ1+AB8D3Ic01d1hJ1dJ6T2ooc1awDCy11jfVl4F3wCzwvcbr78RAy/ZtKHmbm9Q5YKGJ/wd1JcmNWqzkzQyOxcB+h7qzrH8DVxqRNQ5VV0rseT1A0Tw3MdRlb58kd8opTXIPeDt0qmprw59vYqeBg6P6NakdMh7UnSVvHXANOMV4NG6qF6tocz97mrqbgDSxTeoF4AnwaSLJFfVEsSOl7jPgPbCR8fzdAm4k+VK7L4Jn1KPA8eYtPZzkM/AN2JzkQPk8kuTDH0PwzAgc1Fc6AAAAAElFTkSuQmCC" alt="AN2JzkQPk8kuTDH0PwzAgc1Fc6AAAAAElFTkSuQmCC" /></td>
                        </tr>
                    </table>
                    <p style="padding-top: 6pt;text-indent: 0pt;text-align: left;"><br /></p>
                    <p class="s3" style="text-indent: 0pt;text-align: right;">N° Viagem:</p>
                    <p style="text-indent: 0pt;text-align: left;"><br /></p>
                    <table style="border-collapse:collapse;margin-left:6.146pt" cellspacing="0">
                        <tr style="height:15pt">
                            <td style="width:800pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="6" bgcolor="#BFBFBF">
                                <p class="s4" style="padding-top: 1pt;padding-left: 1pt;text-indent: 0pt;text-align: center;">Dados da Partida/Chegada</p>
                            </td>
                        </tr>
                        <tr style="height:27pt">
                            <td style="width:272pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
                                <p class="s5" style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Local de saída da embarcação:</p>
                                <span style="margin-left:40px;" >${dados1.atividade.Veiculo?.LocalEmbarque.nome}</span>
                            </td>
                            <td style="width:256pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
                                <p class="s6" style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Data/Hora da saída:</p>
                                <span style="margin-left:40px;" >${dataInicioPTBR}</span>
                            </td>
                        </tr>
                        <tr style="height:27pt">
                            <td style="width:272pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
                                <p class="s6" style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Destino da viagem/roteiro da embarcação:</p>
                                <span style="margin-left:40px;" >${dados1.atividade.Veiculo?.destino}</span>
                            </td>
                            <td style="width:256pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
                                <p class="s5" style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">Previsão de duração da viagem/roteiro:</p>
                                <span style="margin-left:40px;" >${dados1.atividade.duracao}</span>
                            </td>
                        </tr>
                        <tr style="height:27pt">
                        <td style="width:272pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
                            <p class="s7" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Local de chegada da embarcação:</p>
                            <span style="margin-left:40px;" >${dados1.atividade.Veiculo?.LocalDesembarque.nome}</span>
                        </td>
                        <td style="width:256pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
                            <p class="s5" style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Data/Hora estimada da chegada:</p>
                            <span style="margin-left:40px;" >${dataFimPTBR}</span>
                        </td>
                        </tr>
                        <tr style="height:16pt">
                        <td style="width:528pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="6" bgcolor="#BFBFBF">
                            <p class="s4" style="padding-top: 2pt;padding-left: 1pt;text-indent: 0pt;text-align: center;">Dados da Embarcação</p>
                        </td>
                        </tr>
                        <tr style="height:27pt">
                        <td style="width:184pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2">
                            <p class="s6" style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">Nome da Embarcação.</p>
                            <span style="margin-left:40px;" >${dados1.atividade.Veiculo?.nome}</span>
                        </td>
                        <td style="width:173pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2">
                            <p class="s6" style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Tipo de Embarcação.</p>
                            <span style="margin-left:40px;" >${dados1.atividade.Veiculo?.tipoVeiculo}</span>
                        </td>
                        <td style="width:171pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2">
                            <p class="s6" style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">Porto de Inscrição:</p>
                            <span style="margin-left:40px;" >${dados1.atividade.Veiculo?.pontoIncricao}</span>
                        </td>
                        </tr>
                        <tr style="height:27pt">
                        <td style="width:184pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2">
                            <p class="s6" style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">N° de Inscrição:</p>
                            <span style="margin-left:40px;" >${dados1.atividade.Veiculo?.inscricao}</span>
                        </td>
                        <td style="width:173pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2">
                            <p class="s6" style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">N° de tripulantes:</p>
                            <span style="margin-left:40px;" >1 + ${dados1.atividade.Veiculo?.capacidade}</span>
                        </td>
                        <td style="width:171pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt colspan="2"">
                            <p class="s6" style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">Arqueaçíio Bruta:</p>
                            <span style="margin-left:40px;" >${dados1.atividade.Veiculo?.arquecaoBruta}</span>
                        </td>
                        </tr>
                        <tr style="height:15pt">
                        <td style="width:528pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="6" bgcolor="#BFBFBF">
                            <p class="s4" style="padding-top: 1pt;padding-left: 1pt;text-indent: 0pt;text-align: center;">Dados do Representante/Empresa proprietário(a) da Embarcação</p>
                        </td>
                        </tr>
                        <tr style="height:25pt">
                        <td style="width:528pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="6">
                            <p class="s8" style="padding-top: 2pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Nome:</p>
                            <span style="margin-left:40px;" >${dados1.atividade.Empresa.rasao_social}</span>
                        </td>
                        </tr>
                        <tr style="height:27pt">
                        <td style="width:272pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
                            <p class="s9" style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">CNPJ/CPF:</p>
                            <span style="margin-left:40px;" >${dados1.atividade.Empresa.cnpj}</span>
                        </td>
                        <td style="width:256pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
                            <p class="s5" style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">Telefone.</p>
                            <span style="margin-left:40px;" >${(dados1.atividade.Empresa.User.telefone === undefined || dados1.atividade.Empresa.User.telefone=== null) ? '' : dados1.atividade.Empresa.User.telefone}</span>
                        </td>
                        </tr>
                        <tr style="height:27pt">
                        <td style="width:272pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
                            <p class="s6" style="padding-top: 1pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Endereço.</p>
                            <span style="margin-left:40px;" >${dados1.atividade.Empresa.Endereco.bairro + ' ' + dados1.atividade.Empresa.Endereco.rua + ' ' + dados1.atividade.Empresa.Endereco.numero}</span>
                        </td>
                        <td style="width:256pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="3">
                            <p class="s6" style="padding-top: 1pt;padding-left: 6pt;text-indent: 0pt;text-align: left;">E-mail:</p>
                            <span style="margin-left:40px;" >${dados1.atividade.Empresa.User.email}</span>
                        </td>
                        </tr>
                        <tr style="height:15pt">
                            <td style="width:528pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:2pt;border-right-style:solid;border-right-width:1pt" colspan="6" bgcolor="#BFBFBF">
                                <p class="s4" style="padding-top: 1pt;padding-left: 1pt;text-indent: 0pt;text-align: center;">Dados do Comandante da Embarcação</p>
                            </td>
                        </tr>
                        <tr style="height:31pt">
                            <td style="width:264pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:2pt;border-right-style:solid;border-right-width:2pt" colspan="3">
                                <p class="s8" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Nome:</p>
                                <span style="margin-left:40px;" >${dados1.colaborador.nome}</span>
                            </td>
                            <td style="width:264pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:2pt;border-right-style:solid;border-right-width:2pt" colspan="3">
                                <p class="s6" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Categoria:</p>
                                <span style="margin-left:40px;" >${dados1.colaborador.habilitacao_marinheiro}</span>
                            </td>
                        </tr>
                        <tr style="height:23pt">
                            <td style="width:264pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:2pt;border-right-style:solid;border-right-width:2pt" colspan="3">
                                <p class="s6" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">N° de inscrição da CIR:</p>
                                <span style="margin-left:40px;" >${dados1.colaborador.inscricao}</span>
                            </td>
                            <td style="width:264pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:2pt;border-right-style:solid;border-right-width:2pt" colspan="3">
                                <p class="s8" style="padding-top: 4pt;padding-left: 5pt;text-indent: 0pt;text-align: left;">Telefone</p>
                                <span style="margin-left:40px;" >${dados1.colaborador.telefone}</span>
                            </td>
                        </tr>
                        <tr style="height:16pt">
                            <td style="width:528pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:2pt;border-right-style:solid;border-right-width:1pt" colspan="6" bgcolor="#BFBFBF">
                                <p class="s4" style="padding-top: 2pt;padding-left: 1pt;text-indent: 0pt;text-align: center;">Lista de Passageiros</p>
                            </td>
                        </tr>
                        <tr style="height:28pt">
                            <td style="width:18pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                <p class="s6" style="padding-top: 8pt;padding-left: 2pt;text-indent: 0pt;text-align: center;">N°</p>
                            </td>
                            <td style="width:180pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                <p class="s6" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: center;">Nome completo</p>
                            </td>
                            <td style="width:69pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                <p class="s5" style="padding-top: 8pt;padding-left: 11pt;text-indent: 0pt;text-align: left;">Nacionalidade</p>
                            </td>
                            <td style="width:69pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                <p class="s5" style="padding-top: 8pt;padding-left: 11pt;text-indent: 0pt;text-align: left;">Naturalidade</p>
                            </td>
                            <td style="width:124pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                <p class="s5" style="padding-top: 8pt;padding-left: 28pt;text-indent: 0pt;text-align: left;">Data de Nascimento</p>
                            </td>
                            <td style="width:104pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                <p class="s6" style="padding-top: 8pt;padding-left: 2pt;text-indent: 0pt;text-align: center;">CPF/Passaporte</p>
                            </td>
                        </tr>
                        ${lista}
                    </table>

                     <div class="footer">
                        <p>Local <span class="underline"></span>, em <span class="underline"></span> de <span class="underline"></span></p>
                        <p>Data <span class="underline"></span></p>
                    </div>

                    <div class="signature">
                        <p>Assinatura:</p>
                        <div class="underline signature-line"></div>
                    </div>

                    <div class="page-break"></div>

                     <div class="page">
                        <div style="padding: 10px;">
                            <h6>TERMO DE ANUÊNCIA E ACEITE CONJUNTO</h6>
                            <p>Os abaixo assinados, passageiros da embarcação ${dados1.atividade.Veiculo?.nome}, atestam anuência e aceite em relação ao uso de equipamentos de segurança e orientações sobre as regras.</p>
                            <p><strong>Data do Passeio:</strong> ${dataInicioPTBR}</p>
                            <p><strong>Saída:</strong> ${dataInicioPTBR}</p>
                            <p><strong>Retorno:</strong> ${dataFimPTBR}</p>
                            <p>Atestam anuência e aceite em relação ao uso de equipamentos de segurança e orientações sobre as
                                regras de entrada nos atrativos cânions de capitólio cânions vale dos tucanos e morro dos cabritos, 
                                bem como orientação acerca dos riscos que envolve qualquer tipo de turismo de natureza.
                            </p>
                            <h6>DAS REGRAS DE USO E DOS EQUIPAMENTOS DE SEGURANÇA</h6>
                            <p>
                                Em caráter temporário, até que se finalize todo plano de monitoramento dos atrativos cânions de 
                                capitólio e cânions Vale dos Tucanos e Morro dos Cabritos, a entrada nos atrativos terá um fluxo reduzido 
                                de embarcações não sendo autorizado à aproximação dos paredões e parada da embarcação. Para que 
                                seja minimizado todo e qualquer risco, ainda que mínimo, durante o trajeto no atrativo utilizaremos 
                                capacete de proteção e colete salva vidas. O local será vistoriado todos os dias por geólogo e no caso de 
                                chuva a entrada no atrativo será suspensa até nova vistoria. Durante todo o dia equipe de profissionais do 
                                Município estarão no local para controle do fluxo e apoio aos visitantes. Considerando que todo turismo 
                                de natureza envolve naturalmente algum tipo de risco, todo trabalho e normas expostas visam preservar 
                                a segurança e proporcionar ao visitante um turismo responsável e sustentável.
                            </p>
                        </div>
                    </div>

                    <table style="border-collapse:collapse;margin-left:6.146pt" cellspacing="0">
                        <tr style="height:28pt">
                            <td style="width:528pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:2pt;border-right-style:solid;border-right-width:1pt" colspan="6" bgcolor="#BFBFBF">
                                <p class="s4" style="padding-top: 2pt;padding-left: 1pt;text-indent: 0pt;text-align: center;">Lista de Passageiros</p>
                            </td>
                        </tr>
                        <tr style="height:28pt">
                            <td style="width:28pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                <p class="s6" style="padding-top: 8pt;padding-left: 2pt;text-indent: 0pt;text-align: center;">N°</p>
                            </td>
                            <td style="width:203pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                <p class="s6" style="padding-top: 8pt;padding-left: 1pt;text-indent: 0pt;text-align: center;">Nome completo</p>
                            </td>
                            <td style="width:69pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                <p class="s5" style="padding-top: 8pt;padding-left: 11pt;text-indent: 0pt;text-align: left;">Nacionalidade</p>
                            </td>
                            <td style="width:69pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                <p class="s5" style="padding-top: 8pt;padding-left: 11pt;text-indent: 0pt;text-align: left;">Naturalidade</p>
                            </td>
                            <td style="width:124pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                <p class="s5" style="padding-top: 8pt;padding-left: 28pt;text-indent: 0pt;text-align: left;">Data de Nascimento</p>
                            </td>
                            <td style="width:104pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                <p class="s6" style="padding-top: 8pt;padding-left: 2pt;text-indent: 0pt;text-align: center;">CPF/Passaporte</p>
                            </td>
                        </tr>
                        ${lista}
                    </table>
                    </body>                    
                    </html>
                `;
    
        const element = document.createElement("div");
        element.innerHTML = htmlContent;
        document.body.appendChild(element);
    
        const options = {
            filename: `termo_Atividade_${dados1.atividade.id}_Empresa_${dados1.atividade.empresa_id}.pdf`,
            html2canvas: { scale: 2 }, // Melhora a qualidade da renderização
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };
    
        if (item === 1) {
            html2pdf()
                .from(element)
                .set(options)
                .output('arraybuffer')
                .then((arrayBuffer:any) => {
                    // Criar o File a partir do ArrayBuffer
                    const file = new File([arrayBuffer], `termo_Atividade_${dados1?.atividade.id}_Empresa_${dados1?.atividade.empresa_id}.pdf`, { type: 'application/pdf' });

                    // Enviar o arquivo
                    enviarPDF(file);
                });
                // .from(element)
                // .set(options)
                // .toPdf()
                // .get('pdf')
                // .then((pdf: jsPDF) => {
                //     const pdfBlob = pdf.output('blob');
                //     const url = URL.createObjectURL(pdfBlob);
                //     enviarPDF(url);  // Envia o PDF
                // });
        } else {
            html2pdf()
                .from(element)
                .set(options)
                .save();  // Baixa o PDF
        }
    
        document.body.removeChild(element);  // Remove o elemento do DOM após gerar o PDF
    }
    
    

    if (!dados1) {
        return <div>Carregando...</div>; // Exibe uma mensagem de carregamento até que `dados1` seja definido
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "5px"}}>
            <div style={{paddingBottom: "100px" }}>

                <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                    {/* Substituir a ação de voltar com uma navegação ou outra lógica */}
                    <span>← Voltar</span>
                </div>

                <div style={{ textAlign: "center" }}>

                    <p style={{ fontSize: 20, color: "#0D2041", textTransform: "capitalize",textAlign: "center"  }}>
                        {dados1.atividade.Empresa.rasao_social}
                    </p>
                    <p style={{ fontSize: 14, color: "#6F7399", textAlign: "center"  }}>
                        {formatarDataParaPTBR(dados1.atividade.data_inicio)}
                    </p>
                </div>

                <div style={{ marginTop: "10px", borderBottom: "1px solid #D5D6DA", textAlign: "center", padding: "3%" }}>
                    <p style={{ fontSize: 16, color: "#052937", textTransform: "capitalize",textAlign: "center"  }}>
                        Quantidade de Pessoas
                    </p>
                    <p style={{ fontSize: 20, color: "#052937", marginTop: "2%" }}>
                        {qtde_passageiros.current} Pessoas
                    </p>
                </div>

                <div style={styles.box3}>

                    <p style={{ fontSize: 16, color: "#052937", textTransform: "capitalize", textAlign: "center" }}>Nome da Embarcação/Veículo</p>
                    <p style={{  fontSize: 20, color: "#052937", textTransform: "capitalize", textAlign: "center" }}>
                        {dados1.atividade.Veiculo?.nome}
                    </p>

                    <div style={styles.box3sub}>
                        <div>
                            <p style={{padding: "3%"}}> Capacidade: {dados1.atividade.Veiculo?.capacidade} pessoas</p>

                            <div style={{display: "flex", flexDirection: "row", padding: "3px"}}>
                                <div style={styles.bolinha}></div>
                                <p style={styles.subtext}>Ponto de Embarque: {dados1.atividade.Veiculo?.LocalEmbarque.local}</p>
                            </div>
                            
                            <div style={{display: "flex", flexDirection: "row", padding: "3px"}}>
                                <div style={styles.bolinha}></div>
                                <p style={styles.subtext}>Proprietário: {dados1.atividade.Empresa.rasao_social}</p>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", padding: "3px"}}>
                                <div style={styles.bolinha}></div>
                                <p style={styles.subtext}>Duração: {dados1.atividade.duracao}</p>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div style={{borderBottom: "0.6px", height: "13%", flexDirection: "row", padding: "3%"}}>
                    <div style={styles.subbox}>
                        <p style={styles.text2}>Endereço</p>
                        <p style={styles.text4}>{dados1.atividade.Empresa.Endereco.rua}</p>
                    </div>
                    <div style={styles.subbox}>
                        <p style={styles.text2}>CNPJ</p>
                        <p style={styles.text4}>{dados1.atividade.Empresa.cnpj}</p>
                    </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", borderBottom: "0.6px", height: "13%", padding: "3%" }}>

                    <div style={{ width: "400px", display: "flex", marginTop: "10px", textAlign: "center" , alignContent: "center", justifyContent: "center" }}>
                        <button
                            style={{
                                width: "300px",
                                padding: "10px 20px",
                                fontSize: "16px",
                                cursor: "pointer",
                                backgroundColor: "#F74F4F",
                                color: "#fff",
                                border: "none",
                                borderRadius: "10px",
                            }}
                            onClick={() => createPDF(0)}
                        >
                            Gerar Termo PDF
                        </button>
                    </div>

                    <div style={{ width: "400px", display: "flex", marginTop: "10px", textAlign: "center" , alignContent: "center", justifyContent: "center"}}>
                        <button
                            style={{
                                width: "300px",
                                padding: "10px 20px",
                                fontSize: "16px",
                                cursor: "pointer",
                                backgroundColor: "#4f98f7",
                                color: "#fff",
                                border: "none",
                                borderRadius: "10px",
                            }}
                            onClick={() => createPDF(1)}
                        >
                            Enviar termo para Órgão
                        </button>
                    </div>

                </div>


            </div>
        </div>
    );
};

export default TermoAtividades;

const styles = {
    container: {
        flex: 1,
        backgroundColor: '#fff',
        padding: '5%',
    },
    scrollContainer: {
        paddingBottom: 100, // Adicione padding inferior para evitar a tab bar
    },
    box1: {
        alignItems: "center"
    },
    box2: {
        marginTop: 10,
        borderTopWidth: .6,
        borderBottomWidth: .6,
        borderColor: "#D5D6DA",
        alignItems: "center",
        justifyContent: "center",
        padding: '3%',
        height: "10%"
    },
    box3: {
        alignItems: "center",
        justifyContent: "center",
        padding: "3%",
        borderBottomWidth: .6,
        borderColor: "#D5D6DA",
    },
    box3sub: {
        marginTop: '3%',
        minHeight: 104,
        minWidth: "100%",
        borderRadius: 5,
        backgroundColor: "rgba(91, 99, 169, 0.1)",
        alignItems: "center",
        justifyContent: "center",
    },
    box4: {
        borderBottom: "0.6px solid #D5D6DA", // Usando a sintaxe abreviada corretamente
        height: '13%',
        flexDirection: "row",
        padding: '3%',
    },
    
    box5: {
        marginTop: 10,
        borderBottomWidth: .6,
        borderColor: "#D5D6DA",
        alignItems: "center",
        justifyContent: "center",
        padding: '3%',
        height: "10%"
    },
    empresa: {
        fontSize: 20,
        textTransform: "capitalize",
        color: '#0D2041'
    },
    text2: {
        fontSize: 14,
        color: "#6F7399",
    },
    text3: {
        fontSize: 20,
        color: "#052937",
        textTransform: "capitalize",
        marginTop: "2%"
    },
    text5: {
        fontSize: 16,
        color: "#052937",
        textTransform: "capitalize",
        marginTop: "2%"
    },
    containerSubText: {
        flexDirection: "row" as "row" | "column",
        alignItems: "center" as "flex-start" | "flex-end" | "center" | "baseline" | "stretch", // Tipo específico para alignItems
    },
    subtext: {
        color: '#606487',
        fontSize: 12,
    },
    bolinha: {
        marginLeft: 15,
        marginRight: 10,
        backgroundColor: '#878DC5',
        height: 4,
        width: 4,
        borderColor: '#878DC5',
        borderRadius: 10,
        padding: 5
    },
    relatorio: {
        textAlign: "center",
        fontSize: 14,
        color: "#052937",
        marginTop: "3%"
    },
    subbox: {
        borderColor: 'red',
        borderWidth: 0,
        flex: 1
    },
    text4: {
        color: '#052937',
        fontSize: 13,
        marginRight: 5
    }
};
