import { useState, useEffect } from "react";
import { Card } from "../card/card";
import api from "../../../Services/axios";
import { differenceInDays, endOfMonth, endOfWeek, isAfter, isBefore, isSameDay, isToday, isWithinInterval, startOfMonth, startOfWeek, subMonths } from "date-fns";
import DuracaoMediaEstadia from "../componentes-dash/duracaomediaestadia";
import { Recorrencia } from "../componentes-dash/recorrencia";
import { FaixaEtaria } from "../componentes-dash/faixaetaria";
import { DashGenero } from '../componentes-dash/dashgeneros';
import { Locais } from "../componentes-dash/locais";


interface Permanencia {
    checkin: number;
    de_passagem: number;
    checkout: number;
    total: number;
    taxa: number;
}

interface Origem {
    total: number;
    mensal: number;
    semanal: number;
    taxa: number;
}

interface ConheceMunicipio {
    primeiraVisita: number;
    retorno: number;
    taxaPrimeiraVisita: number;
    taxaRetorno: number;
}

interface vis {
    createdAt: Date;
    data_nascimento: Date;
    Viagem: {
        ativa: boolean;
        data_partida: Date;
        data_chegada: Date;
        conhece_municipio: boolean;
    }
    cidade: string;
    nacionalidade: string;
    Cidade: {
        nome: string;
        Estado: {
            nome: string
        }
    }
    pais: string;
}

interface acom {
    createdAt: Date;
    data_nascimento: Date;
    Viagem: {
        ativa: boolean;
        data_partida: Date;
        data_chegada: Date;
        conhece_municipio: boolean;
    };
    cidade: string;
    nacionalidade: string;
    Cidade: {
        nome: string;
        Estado: {
            nome: string
        }
    }
    pais: string;
}

interface Dados {
    totalUnico: number;
    visitantes: vis[];
    acompanhantes: acom[];
}

interface AgrupamentoPorIdade {
    menores: number;
    adultos: number;
    meiaIdade: number;
    idosos: number;
    taxas: {
        menoresTaxa:number;
        adultosTaxa: number;
        meiaIdadeTaxa: number;
        idososTaxa:number;
    }
}

interface GrupoLocal {
    local: string;
    estado?: string;
    contagem: number;
}

function somarAtributos(obj1: any, obj2: any) {
    const resultado = { ...obj1 }; // Copiar o primeiro objeto
  
    for (const key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        // Se a chave existe em ambos, soma os valores
        resultado[key] = (resultado[key] || 0) + obj2[key];
      }
    }
  
    return resultado;
}

// Função para calcular a taxa de crescimento ou queda
const calcularTaxa = (atual: any, passado: any) => {
    if (passado === 0) return atual > 0 ? 100 : 0; // Se não havia ninguém no mês passado, taxa de crescimento é 100% se houver pessoas este mês

    const taxa = ((atual - passado) / passado) * 100;
    return taxa;
};

export const DashbooardPrefeitura = ({dates}:any) => {
    const [dados, setDados] = useState<Dados>({
        totalUnico: 0, 
        visitantes:[{
            data_nascimento: new Date(),
            createdAt: new Date(), 
            Viagem: {ativa: true, data_partida: new Date(), data_chegada: new Date(), conhece_municipio: false},
            cidade: '',
            nacionalidade: '',
            Cidade: {
                nome: '',
                Estado: {
                    nome: ''
                }
            },
            pais: ''
        }],
        acompanhantes: [{
            createdAt: new Date(), 
            data_nascimento: new Date(),
            Viagem: {ativa: true, data_partida: new Date(), data_chegada: new Date(), conhece_municipio: false},
            cidade: '',
            nacionalidade: '',
            Cidade: {
                nome: '',
                Estado: {
                    nome: ''
                }
            },
            pais: ''
        }]
    });
    const [mensal, setMensal] = useState<number>(0)
    const [semanal, setSemanal] = useState<number>(0)
    const [taxa, setTaxa] = useState<number>(0);
    const [permanencia, setPermanencia] = useState<Permanencia>({de_passagem: 0, checkin: 0, checkout:0, total: 0, taxa: 0});
    const [locais, setLocais] = useState<Origem>({total: 0, mensal: 0, semanal: 0, taxa: 0});
    const [brasileiros, setBrasileiros] = useState<Origem>({total: 0, mensal: 0, semanal: 0, taxa: 0});
    const [estrangeiros, setEstrangeiros] = useState<Origem>({total: 0, mensal: 0, semanal: 0, taxa: 0})
    const [conhecemMunicipio, setConhecemMunicipio] = useState<ConheceMunicipio>({primeiraVisita: 0, retorno: 0, taxaPrimeiraVisita: 0, taxaRetorno: 0})
    const [gruposIdades, setGruposIdades] = useState<AgrupamentoPorIdade>({
        menores: 0,
        adultos: 0,
        meiaIdade: 0,
        idosos: 0,
        taxas: {
            menoresTaxa:0,
            adultosTaxa: 0,
            meiaIdadeTaxa: 0,
            idososTaxa:0,
        }
    })
    const [mediaEstadia, setMediaEstadia] = useState<number>(0);
    const [agrupadoCidade, setAgrupadoCidades] = useState<GrupoLocal[]>([{local: 'Cidade', contagem: 0}])
    const [agrupadoEstado, setAgrupadoEstados] = useState<GrupoLocal[]>([{local: 'Estado', contagem: 0}])
    const [agrupadoPaises, setAgrupadoPaises] = useState<GrupoLocal[]>([{local: 'Estado', contagem: 0}]);
    const [isLoading, setIsLoading] = useState(false);

    function filtrarMensal(dados: Dados){
        let { visitantes, acompanhantes } = dados;
        
        // Obter o intervalo do mês atual
        const inicioMesAtualMes = startOfMonth(new Date());
        const fimMesAtualMes = endOfMonth(new Date());

        // Função para filtrar uma lista com base no intervalo
        const filtrarLista = (lista: any) => {
            return lista.filter((item: any) =>
            isWithinInterval(new Date(item.createdAt), {
                start: inicioMesAtualMes,
                end: fimMesAtualMes,
            })
            ).length;
        };

        // Filtrar as duas listas
        const visitantesMesAtual = filtrarLista(visitantes);
        const acompanhantesMesAtual = filtrarLista(acompanhantes);

        // Soma total
        const total = visitantesMesAtual + acompanhantesMesAtual;

        setMensal(total);
    }

    function filtrarSemanal(dados: Dados){
        let { visitantes, acompanhantes } = dados;
        // Obter o início e o fim da semana atual
        const inicioSemanaAtual = startOfWeek(new Date(), { weekStartsOn: 0 }); // Domingo como início da semana
        const fimSemanaAtual = endOfWeek(new Date(), { weekStartsOn: 0 }); // Sábado como fim da semana

        const filtrarListaSem = (lista: any) => {
            return lista.filter((item: any) =>
            isWithinInterval(new Date(item.createdAt), {
                start: inicioSemanaAtual,
                end: fimSemanaAtual,
            })
            ).length;
        };

        const visitantesSemanaAtual = filtrarListaSem(visitantes);
        const acompanhantesSemanaAtual = filtrarListaSem(acompanhantes);

        const totalSem = visitantesSemanaAtual + acompanhantesSemanaAtual;

        setSemanal(totalSem);
    }

    function taxaCrescimento(dados: Dados){
        let { visitantes, acompanhantes } = dados;
        //Taxa de crescimento mensal do mesmo periodo desse mes para o ultimo
        const hoje = new Date();
        const diaAtual = hoje.getDate();

        // Intervalo do mês atual até o dia atual
        const inicioMesAtual = startOfMonth(hoje);
        const fimMesAtual = new Date(hoje.getFullYear(), hoje.getMonth(), diaAtual, 23, 59, 59);

        // Intervalo do mês anterior até o mesmo dia
        const inicioMesPassado = startOfMonth(subMonths(hoje, 1));
        const fimMesPassado = new Date(hoje.getFullYear(), hoje.getMonth() - 1, diaAtual, 23, 59, 59);

        // Função para filtrar uma lista com base no intervalo
        const filtrarListaTaxa = (lista: any, inicio: any, fim: any) => {
            return lista.filter((item: any) =>
            isWithinInterval(new Date(item.createdAt), {
                start: inicio,
                end: fim,
            })
            ).length;
        };

        // Filtrar visitantes e acompanhantes em ambos os períodos
        const visitantesMesAtualTaxa = filtrarListaTaxa(visitantes, inicioMesAtual, fimMesAtual);
        const acompanhantesMesAtualTaxa = filtrarListaTaxa(acompanhantes, inicioMesAtual, fimMesAtual);
        const visitantesMesPassado = filtrarListaTaxa(visitantes, inicioMesPassado, fimMesPassado);
        const acompanhantesMesPassado = filtrarListaTaxa(acompanhantes, inicioMesPassado, fimMesPassado);

        // Soma total de ambos os períodos
        const totalMesAtual = visitantesMesAtualTaxa + acompanhantesMesAtualTaxa;
        const totalMesPassado = visitantesMesPassado + acompanhantesMesPassado;

        // Calcular crescimento ou queda percentual
        const crescimento = totalMesPassado ? 
        ((totalMesAtual - totalMesPassado) / totalMesPassado) * 100
        : 
        totalMesAtual > 0 ? 
        100
        : 
        0;

        setTaxa(crescimento)
    }

    function contarVisitantesEAcompanhantes(dados: Dados) {
        const { visitantes, acompanhantes } = dados;

        const hoje = new Date();
        const diaAtual = hoje.getDate();
      
        // Obter o mesmo período do mês passado
        const inicioMesAtual = startOfMonth(hoje);
        const fimMesAtual = new Date(hoje.getFullYear(), hoje.getMonth(), diaAtual, 23, 59, 59);
        const inicioMesPassado = startOfMonth(subMonths(hoje, 1));
        const fimMesPassado = new Date(hoje.getFullYear(), hoje.getMonth() - 1, diaAtual, 23, 59, 59);
      
        // Função para categorizar uma lista com base nos critérios
        const categorizarLista = (lista: any) => {
            const de_passagem = lista.filter((item: vis) =>
                item.Viagem &&
                isToday(new Date(item.Viagem.data_chegada)) &&
                isToday(new Date(item.Viagem.data_partida))
            ).length;
        
            const checkin = lista.filter((item: any) =>
                item.Viagem &&
                isToday(new Date(item.Viagem.data_chegada)) &&
                !isToday(new Date(item.Viagem.data_partida))
            ).length;
        
            const checkout = lista.filter((item: any) =>
                item.Viagem &&
                isBefore(new Date(item.Viagem.data_chegada), new Date()) &&
                isToday(new Date(item.Viagem.data_partida))
            ).length;

            const total = lista.filter((item: any) => 
                item.Viagem && 
                item.Viagem.ativa === true
            ).length

           // Filtrar viagens no mesmo período do mês atual e do mês passado
            const viagensMesAtual = lista.filter((item: any) =>
            item.Viagem &&
            isAfter(new Date(item.Viagem.data_chegada), inicioMesAtual) &&
            isBefore(new Date(item.Viagem.data_chegada), fimMesAtual)
            ).length;
        
            const viagensMesPassado = lista.filter((item: any) =>
                item.Viagem &&
                isAfter(new Date(item.Viagem.data_chegada), inicioMesPassado) &&
                isBefore(new Date(item.Viagem.data_chegada), fimMesPassado)
            ).length;
        
            // Calcular taxa de crescimento ou queda
            const taxa = viagensMesPassado
                ? ((viagensMesAtual - viagensMesPassado) / viagensMesPassado) * 100
                : viagensMesAtual > 0
                ? 100
            : 0;
      
          return { de_passagem, checkin, checkout, total, taxa }
        };

        const estadiaVisitantes = categorizarLista(visitantes);
        const estadiaAcompanhantes = categorizarLista(acompanhantes);

        setPermanencia(somarAtributos(estadiaVisitantes, estadiaAcompanhantes));
    }

    function contarVisitantesLocais(dados: Dados){
        const { visitantes, acompanhantes } = dados;
        const capitolio = "Capitólio";
        const hoje = new Date();
        const diaAtual = hoje.getDate();

        const inicioMesAtual = startOfMonth(hoje);
        const fimMesAtual = new Date(hoje.getFullYear(), hoje.getMonth(), diaAtual, 23, 59, 59);

        const inicioMesPassado = startOfMonth(subMonths(hoje, 1));
        const fimMesPassado = new Date(hoje.getFullYear(), hoje.getMonth() - 1, diaAtual, 23, 59, 59);

        const inicioSemanaAtual = startOfWeek(hoje, { weekStartsOn: 0 });
        const fimSemanaAtual = endOfWeek(hoje, { weekStartsOn: 0 });

        const todasPessoas = [...visitantes, ...acompanhantes];

        // Filtragem dos valores
        const total = todasPessoas.filter((pessoa) => pessoa.cidade === capitolio).length;

        const mensal = todasPessoas.filter(
            (pessoa) =>
            pessoa.cidade === capitolio &&
            isAfter(new Date(pessoa.Viagem.data_chegada), inicioMesAtual) &&
            isBefore(new Date(pessoa.Viagem.data_chegada), fimMesAtual)
        ).length;

        const semanal = todasPessoas.filter(
            (pessoa) =>
            pessoa.cidade === capitolio &&
            isAfter(new Date(pessoa.Viagem.data_chegada), inicioSemanaAtual) &&
            isBefore(new Date(pessoa.Viagem.data_chegada), fimSemanaAtual)
        ).length;

        const mesAtualCapitolio = todasPessoas.filter(
            (pessoa) =>
            pessoa.cidade === capitolio &&
            isAfter(new Date(pessoa.Viagem.data_chegada), inicioMesAtual) &&
            isBefore(new Date(pessoa.Viagem.data_chegada), fimMesAtual)
        ).length;

        const mesPassadoCapitolio = todasPessoas.filter(
            (pessoa) =>
            pessoa.cidade === capitolio &&
            isAfter(new Date(pessoa.Viagem.data_chegada), inicioMesPassado) &&
            isBefore(new Date(pessoa.Viagem.data_chegada), fimMesPassado)
        ).length;

        // Cálculo da taxa
        const taxa = mesPassadoCapitolio
        ? ((mesAtualCapitolio - mesPassadoCapitolio) / mesPassadoCapitolio) * 100
        : mesAtualCapitolio > 0
        ? 100
        : 0;

        setLocais({total, mensal, semanal, taxa})
    }

    function contarVisitantesBrasileiros(dados: Dados){
        const { visitantes, acompanhantes } = dados;
        const nacionalidadeAlvo = "Brasileiro(a)";
        const hoje = new Date();
        const diaAtual = hoje.getDate();

        const inicioMesAtual = startOfMonth(hoje);
        const fimMesAtual = new Date(hoje.getFullYear(), hoje.getMonth(), diaAtual, 23, 59, 59);

        const inicioMesPassado = startOfMonth(subMonths(hoje, 1));
        const fimMesPassado = new Date(hoje.getFullYear(), hoje.getMonth() - 1, diaAtual, 23, 59, 59);

        const inicioSemanaAtual = startOfWeek(hoje, { weekStartsOn: 0 });
        const fimSemanaAtual = endOfWeek(hoje, { weekStartsOn: 0 });

        const todasPessoas = [...visitantes, ...acompanhantes];

        // Filtragem dos valores
        const total = todasPessoas.filter(
            (pessoa) => pessoa.nacionalidade === nacionalidadeAlvo
        ).length;

        const mensal = todasPessoas.filter(
            (pessoa) =>
            pessoa.nacionalidade === nacionalidadeAlvo &&
            isAfter(new Date(pessoa.Viagem?.data_chegada), inicioMesAtual) &&
            isBefore(new Date(pessoa.Viagem?.data_chegada), fimMesAtual)
        ).length;

        const semanal = todasPessoas.filter(
            (pessoa) =>
            pessoa.nacionalidade === nacionalidadeAlvo &&
            isAfter(new Date(pessoa.Viagem?.data_chegada), inicioSemanaAtual) &&
            isBefore(new Date(pessoa.Viagem?.data_chegada), fimSemanaAtual)
        ).length;

        const mesAtualBrasileiros = todasPessoas.filter(
            (pessoa) =>
            pessoa.nacionalidade === nacionalidadeAlvo &&
            isAfter(new Date(pessoa.Viagem?.data_chegada), inicioMesAtual) &&
            isBefore(new Date(pessoa.Viagem?.data_chegada), fimMesAtual)
        ).length;

        const mesPassadoBrasileiros = todasPessoas.filter(
            (pessoa) =>
            pessoa.nacionalidade === nacionalidadeAlvo &&
            isAfter(new Date(pessoa.Viagem?.data_chegada), inicioMesPassado) &&
            isBefore(new Date(pessoa.Viagem?.data_chegada), fimMesPassado)
        ).length;

        // Cálculo da taxa
        const taxa = mesPassadoBrasileiros
        ? ((mesAtualBrasileiros - mesPassadoBrasileiros) / mesPassadoBrasileiros) * 100
        : mesAtualBrasileiros > 0
        ? 100
        : 0;

        setBrasileiros({total, mensal, semanal, taxa})
    }

    function contarVisitantesEstrangeiros(dados: Dados){
        const { visitantes, acompanhantes } = dados;
        const nacionalidade = "Estrangeiro(a)";
        const hoje = new Date();
        const diaAtual = hoje.getDate();

        const inicioMesAtual = startOfMonth(hoje);
        const fimMesAtual = new Date(hoje.getFullYear(), hoje.getMonth(), diaAtual, 23, 59, 59);

        const inicioMesPassado = startOfMonth(subMonths(hoje, 1));
        const fimMesPassado = new Date(hoje.getFullYear(), hoje.getMonth() - 1, diaAtual, 23, 59, 59);

        const inicioSemanaAtual = startOfWeek(hoje, { weekStartsOn: 0 });
        const fimSemanaAtual = endOfWeek(hoje, { weekStartsOn: 0 });

        const todasPessoas = [...visitantes, ...acompanhantes];

        // Filtragem dos valores
        const total = todasPessoas.filter((pessoa) => pessoa.nacionalidade === nacionalidade).length;
        
        const mensal = todasPessoas.filter(
            (pessoa) =>
            pessoa.nacionalidade === nacionalidade &&
            isAfter(new Date(pessoa.Viagem.data_chegada), inicioMesAtual) &&
            isBefore(new Date(pessoa.Viagem.data_chegada), fimMesAtual)
        ).length;

        const semanal = todasPessoas.filter(
            (pessoa) =>
            pessoa.nacionalidade === nacionalidade &&
            isAfter(new Date(pessoa.Viagem.data_chegada), inicioSemanaAtual) &&
            isBefore(new Date(pessoa.Viagem.data_chegada), fimSemanaAtual)
        ).length;

        const mesAtualEstrangeiros = todasPessoas.filter(
            (pessoa) =>
            pessoa.nacionalidade === nacionalidade &&
            isAfter(new Date(pessoa.Viagem.data_chegada), inicioMesAtual) &&
            isBefore(new Date(pessoa.Viagem.data_chegada), fimMesAtual)
        ).length;

        const mesPassadoEstrangeiros = todasPessoas.filter(
            (pessoa) =>
            pessoa.nacionalidade === nacionalidade &&
            isAfter(new Date(pessoa.Viagem.data_chegada), inicioMesPassado) &&
            isBefore(new Date(pessoa.Viagem.data_chegada), fimMesPassado)
        ).length;

        // Cálculo da taxa
        const taxa = mesPassadoEstrangeiros
        ? ((mesAtualEstrangeiros - mesPassadoEstrangeiros) / mesPassadoEstrangeiros) * 100
        : mesAtualEstrangeiros > 0
        ? 100
        : 0;

        setEstrangeiros({total, mensal, semanal, taxa})
    }

    function contaRecorrencias(dados: Dados) {
        const { visitantes, acompanhantes } = dados;
        const todasPessoas = [...visitantes, ...acompanhantes];
        const hoje = new Date();
        const diaAtual = hoje.getDate();

        // Definir intervalos de datas
        const inicioMesAtual = startOfMonth(hoje);
        const fimMesAtual = new Date(hoje.getFullYear(), hoje.getMonth(), diaAtual, 23, 59, 59);

        const inicioMesPassado = startOfMonth(subMonths(hoje, 1));
        const fimMesPassado = new Date(hoje.getFullYear(), hoje.getMonth() - 1, diaAtual, 23, 59, 59);

        // Filtrar viagens de primeira visita e retorno
        const primeiraVisita = todasPessoas.filter(
            (pessoa) => pessoa.Viagem && pessoa.Viagem.conhece_municipio === false
        ).length;

        const retorno = todasPessoas.filter(
            (pessoa) => pessoa.Viagem && pessoa.Viagem.conhece_municipio === true
        ).length;

        // Calcular viagens do mês atual e mês passado para primeira visita
        const primeiraVisitaMesAtual = todasPessoas.filter(
            (pessoa) =>
            pessoa.Viagem &&
            pessoa.Viagem.conhece_municipio === false &&
            isAfter(new Date(pessoa.Viagem.data_chegada), inicioMesAtual) &&
            isBefore(new Date(pessoa.Viagem.data_chegada), fimMesAtual)
        ).length;

        const primeiraVisitaMesPassado = todasPessoas.filter(
            (pessoa) =>
            pessoa.Viagem &&
            pessoa.Viagem.conhece_municipio === false &&
            isAfter(new Date(pessoa.Viagem.data_chegada), inicioMesPassado) &&
            isBefore(new Date(pessoa.Viagem.data_chegada), fimMesPassado)
        ).length;

        // Calcular viagens do mês atual e mês passado para retorno
        const retornoMesAtual = todasPessoas.filter(
            (pessoa) =>
            pessoa.Viagem &&
            pessoa.Viagem.conhece_municipio === true &&
            isAfter(new Date(pessoa.Viagem.data_chegada), inicioMesAtual) &&
            isBefore(new Date(pessoa.Viagem.data_chegada), fimMesAtual)
        ).length;

        const retornoMesPassado = todasPessoas.filter(
            (pessoa) =>
            pessoa.Viagem &&
            pessoa.Viagem.conhece_municipio === true &&
            isAfter(new Date(pessoa.Viagem.data_chegada), inicioMesPassado) &&
            isBefore(new Date(pessoa.Viagem.data_chegada), fimMesPassado)
        ).length;

        // Cálculo das taxas
        const taxaPrimeiraVisita = primeiraVisitaMesPassado
        ? ((primeiraVisitaMesAtual - primeiraVisitaMesPassado) / primeiraVisitaMesPassado) * 100
        : primeiraVisitaMesAtual > 0
        ? 100
        : 0;

        const taxaRetorno = retornoMesPassado
        ? ((retornoMesAtual - retornoMesPassado) / retornoMesPassado) * 100
        : retornoMesAtual > 0
        ? 100
        : 0;

        setConhecemMunicipio({primeiraVisita, retorno, taxaPrimeiraVisita, taxaRetorno});
    }

    function duracaoMediaEstadia(dados: Dados){
        let {visitantes, acompanhantes} = dados

        // Combinar as viagens de visitantes e acompanhantes
        const viagens = [
            ...visitantes.map(v => v.Viagem).filter(v => v), // Apenas viagens válidas
            ...acompanhantes.map(a => a.Viagem).filter(a => a),
        ];

        if (viagens.length === 0) return 0; // Nenhuma viagem disponível

        // Calcular a média
        const totalDias = viagens.reduce((soma, viagem) => {
            const dataChegada = new Date(viagem.data_chegada);
            const dataPartida = new Date(viagem.data_partida);

            // Diferença em dias usando date-fns
            const diferencaEmDias = differenceInDays(dataPartida, dataChegada);
            return soma + diferencaEmDias;
        }, 0);

        setMediaEstadia(totalDias / viagens.length); // Média
    }

    function agruparCidades(dados: Dados){
        let { visitantes, acompanhantes } = dados;

        // Combinar visitantes e acompanhantes
        const pessoas = [...visitantes, ...acompanhantes];

        // Criar um mapa para contar a ocorrência de cada cidade e estado
        const cidadesMap = new Map<string, { estado: string; contagem: number }>();

        

        pessoas.forEach((pessoa) => {
            // Verificar se a pessoa possui uma cidade e estado associados
            if (pessoa.Cidade && pessoa.Cidade.nome && pessoa.Cidade.Estado && pessoa.Cidade.Estado.nome) {
                const cidadeNome = pessoa.Cidade.nome;
                const estadoNome = pessoa.Cidade.Estado.nome;

                // Criar uma chave única para cidade e estado
                const chave = `${cidadeNome} - ${estadoNome}`;

                // Incrementar a contagem para a cidade/estado
                if (cidadesMap.has(chave)) {
                    cidadesMap.get(chave)!.contagem++;
                } else {
                    cidadesMap.set(chave, { estado: estadoNome, contagem: 1 });
                }
            }
        });

        // Converter o mapa em um array no formato desejado
        const resultado = Array.from(cidadesMap.entries())
        .map(([chave, { estado, contagem }]) => {
            const [local] = chave.split(" - "); // Extrair apenas o nome da cidade
            return { local, estado, contagem };
        })
        .sort((a, b) => b.contagem - a.contagem);

        setAgrupadoCidades(resultado);
    }

    function agruparEstados(dados: Dados){
        let { visitantes, acompanhantes } = dados;

        // Combinar visitantes e acompanhantes
        const pessoas = [...visitantes, ...acompanhantes];

        // Criar um mapa para contar a ocorrência de cada cidade
        const estadosMap = new Map<string, number>();

        pessoas.forEach((pessoa) => {
            // Verificar se a pessoa possui uma cidade associada
            if (pessoa.Cidade && pessoa.Cidade.Estado && pessoa.Cidade.Estado.nome) {
                const estadoNome = pessoa.Cidade.Estado.nome;

                // Incrementar a contagem para a cidade
                estadosMap.set(estadoNome, (estadosMap.get(estadoNome) || 0) + 1);
            }
        });

        const resultado = Array.from(estadosMap.entries())
        .map(([local, contagem]) => ({ local, contagem }))
        .sort((a, b) => b.contagem - a.contagem);

        setAgrupadoEstados(resultado);
    }

    function agruparPaises(dados: Dados){
        let { visitantes, acompanhantes } = dados;

        let pessoas = [...visitantes, ...acompanhantes];

        const paisesMap = new Map<string, number>();

        pessoas.forEach((pessoa) => {
            const pais = pessoa.pais;
            paisesMap.set(pais, (paisesMap.get(pais) || 0) +1 );
        })

        const resultado = Array.from(paisesMap.entries())
        .map(([local, contagem]) => ({ local, contagem }))
        .sort((a, b) => b.contagem - a.contagem);

        setAgrupadoPaises(resultado);
    }

    function agruparFaixaEtaria(dados: Dados){
        let { visitantes, acompanhantes } = dados;

        // Combina as listas de visitantes e acompanhantes
        const pessoas = [
            ...visitantes.map(v => ({ data_nascimento: new Date(v.data_nascimento), createdAt: new Date(v.createdAt) })),
            ...acompanhantes.map(a => ({ data_nascimento: new Date(a.data_nascimento), createdAt: new Date(a.createdAt) })),
        ];
        
        // Função para agrupar as pessoas por faixa etária com base na data de nascimento
        const agruparPorFaixaEtaria = (pessoas: any[]) => {
            const agora = new Date();
            const faixas = {
                menores: 0,
                adultos: 0,
                meiaIdade: 0,
                idosos: 0,
            };
        
            pessoas.forEach(pessoa => {
                const idade = agora.getFullYear() - pessoa.data_nascimento.getFullYear();
        
                if (idade < 18) {
                    faixas.menores++;
                } else if (idade >= 18 && idade <= 34) {
                    faixas.adultos++;
                } else if (idade >= 35 && idade <= 49) {
                    faixas.meiaIdade++;
                } else {
                    faixas.idosos++;
                }
            });
        
            return faixas;
        };
        
        // Agrupar as pessoas criadas neste mês por faixa etária
        const faixasAtuais = agruparPorFaixaEtaria(pessoas);
        
        
        // Obter as datas do mês passado até o mesmo dia do mês passado
        const hoje = new Date();
        const inicioMesPassado = startOfMonth(subMonths(hoje, 1));
        const fimMesPassado = isSameDay(hoje, startOfMonth(hoje)) ? hoje : endOfMonth(subMonths(hoje, 1));
        
        // Filtrar pessoas criadas no mês passado
        const pessoasMesPassado = pessoas.filter(pessoa => {
            const createdAt = pessoa.createdAt;
            return isBefore(createdAt, fimMesPassado) && !isBefore(createdAt, inicioMesPassado);
        });

        // Agrupar as pessoas criadas no mês passado por faixa etária
        const faixasPassado = agruparPorFaixaEtaria(pessoasMesPassado);

        // Calcular as taxas de crescimento/queda
        const taxaMenores = calcularTaxa(faixasAtuais.menores, faixasPassado.menores);
        const taxaAdultos = calcularTaxa(faixasAtuais.adultos, faixasPassado.adultos);
        const taxaMeiaIdade = calcularTaxa(faixasAtuais.meiaIdade, faixasPassado.meiaIdade);
        const taxaIdosos = calcularTaxa(faixasAtuais.idosos, faixasPassado.idosos);
    
        setGruposIdades(
            {
                ...faixasAtuais,
                taxas: {
                    menoresTaxa: taxaMenores,
                    adultosTaxa: taxaAdultos,
                    meiaIdadeTaxa: taxaMeiaIdade,
                    idososTaxa: taxaIdosos
                },
            }
        )
    }

    //Total Visitantes
    useEffect(() => {
        const fetchData = async () => {
            try{
                setIsLoading(true);

                const response = await api.get(`/dashboard/total-registros?start=${dates[0]}&end=${dates[1]}`, 
                    {headers: {"Authorization" : "Bearer " + localStorage.getItem("accessToken")}});

                setIsLoading(false);

                if(response.status === 200){
                    setDados(response.data);
                }
            }catch(error){
                console.log(error);
            }
        }

        fetchData();
    }, [dates])

    // Total de Visitantes Tratamento
    useEffect(() => {
        filtrarMensal(dados);
        filtrarSemanal(dados);
        taxaCrescimento(dados);
        contarVisitantesEAcompanhantes(dados);
        contarVisitantesLocais(dados);
        contarVisitantesBrasileiros(dados);
        contarVisitantesEstrangeiros(dados);
        contaRecorrencias(dados);
        duracaoMediaEstadia(dados);
        agruparFaixaEtaria(dados);
        agruparCidades(dados);
        agruparEstados(dados);
        agruparPaises(dados);
    }, [dados])
    

    if(isLoading){
        return <>Carregando...</>
    }

    return (
        <div style={{display: 'flex', alignItems: 'stretch', justifyContent: 'space-between', gap: '5px', width: '100%', flexWrap: 'wrap'}}>
            <Card 
                title={'Total de visitantes'} 
                subtitle1={'ESSE MÊS'} 
                subtitle2={'ESSA SEMANA'} 
                var1={dados?.totalUnico} 
                var2={mensal} 
                var3={semanal}
                taxa={String(Math.round(taxa)) || '0'}
                hasImg={true}
                hasUnderline={false}
            />
            <Card 
                title={'Permanecem no município'} 
                subtitle1={'CHECKIN'} 
                subtitle2={'CHECKOUT'} 
                subtitle3={'DE PASSAGEM'}
                var1={permanencia.total} 
                var2={permanencia.checkin} 
                var3={permanencia.checkout}
                var4={permanencia.de_passagem}
                taxa={String(Math.round(permanencia.taxa)) || '0'}
                hasImg={false}
                hasUnderline={false}
            />

            <DuracaoMediaEstadia mediaEstadia={mediaEstadia}/>

            <Recorrencia 
                primeiraVisita={conhecemMunicipio.primeiraVisita}
                retorno={conhecemMunicipio.retorno}
                taxaPrimeiraVisita={conhecemMunicipio.taxaPrimeiraVisita}
                taxaRetorno={conhecemMunicipio.taxaRetorno}
            />  

            <DashGenero 
                data={dados}
            />

            <FaixaEtaria 
                menores={gruposIdades.menores}
                adultos={gruposIdades.adultos}
                meiaIdade={gruposIdades.meiaIdade}
                idosos={gruposIdades.idosos}
                taxas={gruposIdades.taxas}
            />
            
            <Card 
                title={'Total de visitantes locais'} 
                subtitle1={'ESSE MÊS'} 
                subtitle2={'ESSA SEMANA'} 
                subtitle3={''}
                var1={locais.total} 
                var2={locais.mensal} 
                var3={locais.semanal}
                taxa={String(Math.round(locais.taxa)) || '0'}
                hasImg={true}
                hasUnderline={true}
            />
            <Card 
                title={'Total de visitantes brasileiros'} 
                subtitle1={'ESSE MÊS'} 
                subtitle2={'ESSA SEMANA'} 
                subtitle3={''}
                var1={brasileiros.total} 
                var2={brasileiros.mensal} 
                var3={brasileiros.semanal}
                taxa={String(Math.round(brasileiros.taxa)) || '0'}
                hasImg={true}
                hasUnderline={true}
            />

            <Card 
                title={'Total de visitantes estrangeiros'} 
                subtitle1={'ESSE MÊS'} 
                subtitle2={'ESSA SEMANA'} 
                subtitle3={''}
                var1={estrangeiros.total} 
                var2={estrangeiros.mensal} 
                var3={estrangeiros.semanal}
                taxa={String(Math.round(estrangeiros.taxa)) || '0'}
                hasImg={true}
                hasUnderline={true}
            />

            
            <div style={{display: 'flex', gap: '5px', width: '100%'}}>
                <Locais
                    title="Cidades" 
                    showLocation={true}
                    data={agrupadoCidade}
                />
                
                <Locais
                    title="Estados" 
                    showLocation={true}
                    data={agrupadoEstado}
                />

                <Locais
                    title="Países" 
                    showLocation={false}
                    data={agrupadoPaises}
                />
            </div>
        </div>
    )
}