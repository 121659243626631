import paisesData from "./../assets/data/paises.json";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { 
    Schema, 
    Form, 
    MaskedInput, 
    RadioGroup, 
    Radio, 
    Message, 
    toaster, 
    Button, 
    Grid, 
    Row, 
    Col, 
    InputPicker, 
    TagPicker, 
    InputNumber, 
    DatePicker
} from "rsuite";
import { Field } from "../components/Input/Field";
import axios from "axios";
import api from "../Services/axios";
const { StringType, NumberType } = Schema.Types;

function toThousands(value: string) {
    return value 
      ? `R$ ${`${value}`.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')}` 
      : value;
};

const tipoVeiculo = [
    'Aéreo',
    'Automotivo',
    'Embarcação',
    'Tração animal',
].map(value => ({label: value, value: value}))

const atividadesAquaticas = [
    'Balsa',
    'Barcaça',
    'Bote',
    'Chata',
    'Escuna',
    'Flutuante',
    'Hovercraft',
    'Jangada',
    'Lancha',
    'Saveiro',
    'Traineira',
    'Veleiro',
    'Iate',
].map(value => ({label: value, value: value}))

const atividadesAereas = [
    'Avião',
    'Asa-Delta',
    'Balão',
    'Helicóptero',
    'Parapente',
    'Paramotor',
    'Paraqueda',
    'Skydiving',
    'Indoor',
].map(value => ({label: value, value: value}))

const atividadesTerrestres = [
    'Jeeps',
    'SUVs',
    'Bugres',
    'Quadriciclos',
].map(value => ({label: value, value: value}))

const atividadesTracaoAnimal = [
    'Passeios de Charrete ou Carruagem',
    'Cavalgadas Guiadas',
    'Passeios de Trole ou Carro de Boi',
    'Trilhas e Experiências Temáticas',
    'Passeios de Trenó',
].map(value => ({label: value, value: value}))

const roteiros = [
    'Lagoa azul, Canion, Cascata, Ninho dos tucanos',
].map(value => ({label: value, value: value}))

const semTipoVeiculo = [
    'Escolha um tipo de veículo'
].map(value => ({label: value, value: value}))

export const CadastraVeiculo = () => {
    const navigate = useNavigate();

    const formRef = useRef<any>();
    const [cep, setCEP] = useState<string>('');
    const [tipoVeiculoEscolhido, setTipoVeiculoEscolhido] = useState<string>('');
    const [empresasSelecionaveis, setEmpresasSelecionaveis] = useState();
    const [pontosEmbarque, setPontosEmbarque] = useState();
    const [pontosDesembarque, setPontosDesembarque] = useState();
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [formValue, setFormValue] = useState<{ 
            empresa: string; 
            tipoVeiculo: string, 
            nomeVeiculoEmbarcacao: string, 
            qtdeTripulantes: number, 
            qtdePassageiros: number,
            atividadeServico: string,
            arqueacaoBruta: string,
            anoConstrucao: string,
            comprimentoTotal: number,
            numeroSerieCascoChassi: string,
            cep: string,
            estado: string
            cidade: string,
            pontoEmbarque: number,
            pontoDesembarque: number,
            destino: string,
        }>({
        empresa: '',
        tipoVeiculo: '', 
        nomeVeiculoEmbarcacao: '', 
        qtdeTripulantes: 0, 
        qtdePassageiros: 0,
        atividadeServico: '',
        arqueacaoBruta: '',
        anoConstrucao: '',
        comprimentoTotal: 0,
        numeroSerieCascoChassi: '',
        cep: '',
        estado: '',
        cidade: '',
        pontoEmbarque: 0,
        pontoDesembarque: 0,
        destino: ''
    });

    useEffect(() => {
        const fetchCEPData = async () => {
            let response, cidade: string, estado: string;
            const cepLimpo = cep.replace(/[.\-/_]/g, "");

            if(cepLimpo.length > 7){
                try{
                    response = await axios.get(`https://brasilapi.com.br/api/cep/v2/${cepLimpo}`);

                    if(response.status === 200){
                        cidade = response.data.city;
                        estado = response.data.state;

                        setFormValue((prev) => ({
                            ...prev,
                            cidade: cidade,
                            estado: estado
                        }))

                        setCidade(cidade);
                        setEstado(estado);
                    }
                }catch(error){
                    console.log(error);
                }
            }
        }

        fetchCEPData();
    }, [cep])

    useEffect(() => {
        const fetchData = async () =>{
            let response;
            try{
                response = await api.get('/empresa', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }})

                if(response.status === 200){
                    setEmpresasSelecionaveis(response.data.map((item: any)=> ({label: item.rasao_social, value: item.id})))
                }
            }catch(error){  
                console.log(error);
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        const fetchData = async () =>{
            let response;
            try{
                response = await api.get('/pontos-embarque', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }})

                if(response.status === 200){
                    setPontosEmbarque(response.data.map((item: any)=> ({label: item.nome, value: item.id})))
                }
            }catch(error){  
                console.log(error);
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        const fetchData = async () =>{
            let response;
            try{
                response = await api.get('/pontos-desembarque', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }})

                if(response.status === 200){
                    setPontosDesembarque(response.data.map((item: any)=> ({label: item.nome, value: item.id})))
                }
            }catch(error){  
                console.log(error);
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        console.log(formValue);
    }, [formValue])

    const [formError, setFormError] = useState<any>({});

    const handleFormChange = (formValue: Record<string, any>, event?: React.SyntheticEvent) => {
        setFormValue((prevState) => ({
            ...prevState,
            ...formValue,
        }));
    };

    const handleSubmit = async () => {
        if (!formRef.current.check()) {
          toaster.push(<Message type="error">Error</Message>);
          return;
        }
        
        try{
            let response = await api.post('/veiculo', { formValue },
                { headers: {"Authorization" : "Bearer " + localStorage.getItem("accessToken")}});
    
            if(response.status === 201) {
                setFormValue({
                    empresa: '',
                    tipoVeiculo: '', 
                    nomeVeiculoEmbarcacao: '', 
                    qtdeTripulantes: 0, 
                    qtdePassageiros: 0,
                    atividadeServico: '',
                    arqueacaoBruta: '',
                    anoConstrucao: '',
                    comprimentoTotal: 0,
                    numeroSerieCascoChassi: '',
                    cep: '',
                    estado: '',
                    cidade: '',
                    pontoEmbarque: 0,
                    pontoDesembarque: 0,
                    destino: ''
                })
                navigate(-1);
            }
        }catch(error){
            console.log(error);
        }
    };


    const model = Schema.Model({
        anoConstrucao: StringType(),
        arqueacaoBruta: StringType(),
        atividadeServico: StringType().isRequired('Campo obrigatório!'),
        cep: StringType(),
        comprimentoTotal: NumberType(),
        destino: StringType().isRequired('Campo obrigatório'),
        empresa: NumberType().isRequired('Campo obrigatório!'),
        nomeVeiculoEmbarcacao: StringType().isRequired('Campo obrigatório!'), 
        tipoVeiculo: StringType().isRequired('Campo obrigatório!'), 
        qtdeTripulantes: NumberType().isRequired('Campo obrigatório'), 
        qtdePassageiros: NumberType().isRequired('Campo obrigatório'),
        numeroSerieCascoChassi: StringType(),
        pontoEmbarque: NumberType().isRequired('Campo obrigatório!'),
        pontoDesembarque: NumberType().isRequired('Campo obrigatório'),
    });

    return (
        <>
            <Form
                ref={formRef}
                onChange={handleFormChange}
                onCheck={setFormError}
                formValue={formValue}
                model={model}
            >
                <Grid fluid>
                    {/* Primeira Linha */}
                    <Row>
                        <Col style={{maxWidth: 300}}>
                            <Field
                                label="Empresa a qual o veículo pertence"
                                name="empresa"
                                accepter={InputPicker}
                                data={empresasSelecionaveis}
                                style={{width: '100%'}}
                                error={formError.empresa}
                            />
                        </Col>
                        <Col style={{maxWidth: 300}}>
                            <Field 
                                name="nomeVeiculoEmbarcacao"
                                label="Nome do veículo/embarcação"
                                style={{width: '100%'}}
                                error={formError.nomeVeiculoEmbarcacao}
                            />
                        </Col>

                        
                    </Row>

                    {/* Segunda Linha */}
                    <Row>
                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Tipo de veículo"
                                accepter={InputPicker}
                                name="tipoVeiculo"
                                data={tipoVeiculo}
                                onChange={(value: string) => setTipoVeiculoEscolhido(value)}
                                style={{width: '100%'}}
                                error={formError.tipoVeiculo}
                            />
                        </Col>

                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Atividade que se enquadra"
                                name="atividadeServico"
                                accepter={InputPicker}
                                style={{width: '100%'}}
                                data={
                                    tipoVeiculoEscolhido === 'Aéreo' ? atividadesAereas : (
                                        tipoVeiculoEscolhido === 'Automotivo' ? atividadesTerrestres : (
                                            tipoVeiculoEscolhido === 'Embarcação' ? atividadesAquaticas : (
                                                tipoVeiculoEscolhido === 'Tração animal' ? atividadesTracaoAnimal : semTipoVeiculo  
                                            )
                                        )
                                    )
                                }
                                error={formError.atividade}
                            />
                        </Col>
                        
                        
                    </Row>

                    {/*Terceira Linha */}           
                    <Row>
                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Quantidade de passsageiros"
                                accepter={InputNumber}
                                name="qtdePassageiros"
                                style={{width: '100%'}}
                                error={formError.qtdePassageiros}
                            />
                        </Col>

                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Quantidade de tripulantes"
                                accepter={InputNumber}
                                name="qtdeTripulantes"
                                style={{width: '100%'}}
                                error={formError.qtdeTripulantes}
                            />
                        </Col>
                    </Row>

                    {
                        tipoVeiculoEscolhido === "Embarcação" && (
                            <>
                                <Row>
                                    <span style={{display: 'block', fontFamily: 'Poppins-Medium', marginBottom: '2rem', fontSize: '2.6rem'}}>
                                        Características do casco
                                    </span>
                                    <Col style={{maxWidth: 300}}>
                                        <Field 
                                            label="Ano da contrução"
                                            name="anoConstrucao"
                                            style={{width: '100%'}}
                                            accepter={InputNumber}
                                            error={formError.anoConstrucao}
                                        /> 
                                    </Col>

                                    <Col style={{maxWidth: 300}}>
                                        <Field 
                                            label="Comprimento total (metros)"
                                            name="comprimentoTotal"
                                            style={{width: '100%'}}
                                            accepter={InputNumber}
                                            error={formError.comprimento}
                                        /> 
                                    </Col>
                                </Row>
                                
                                {/*  */}
                                <Row>
                                    <Col style={{maxWidth: 300}}>
                                        <Field 
                                            label="Número de série do casco/chassi"
                                            name="numeroSerieCascoChassi"
                                            style={{width: '100%'}}
                                            error={formError.numeroSerieCasco}
                                        /> 
                                    </Col>

                                    <Col style={{maxWidth: 300}}>
                                        <Field 
                                            label="Arqueação bruta"
                                            name="arqueacaoBruta"
                                            style={{width: '100%'}}
                                            accepter={InputNumber}
                                            error={formError.arqueacaoBruta}
                                        /> 
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                    
                    {/*Sexta Linha */}

                    

                    {
                        tipoVeiculoEscolhido === "Embarcação" && (
                        <>
                            <Row>
                                <span style={{display: 'block', fontFamily: 'Poppins-Medium', marginBottom: '2rem', fontSize: '2.6rem'}}>
                                    Embarque e desembarque
                                </span>
                                <Col style={{maxWidth: 300}}>
                                    <Field 
                                        label="Ponto de embarque"
                                        name="pontoEmbarque"
                                        accepter={InputPicker}
                                        data={pontosEmbarque}
                                        style={{width: '100%'}}
                                        error={formError.pontoEmbarque}
                                    /> 
                                </Col>

                                <Col style={{maxWidth: 300}}>
                                    <Field 
                                        label="Ponto de desembarque"
                                        name="pontoDesembarque"
                                        accepter={InputPicker}
                                        data={pontosDesembarque}
                                        style={{width: '100%'}}
                                        error={formError.pontoDesembarque}
                                    /> 
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{maxWidth: 300}}>
                                    <Field 
                                        label="CEP"
                                        name="cep"
                                        accepter={MaskedInput}
                                        style={{width: '100%'}}
                                        onChange={(value: string) => setCEP(value)}
                                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                                        error={formError.cep}
                                    /> 
                                </Col>

                                {
                                    estado && cidade && (
                                        <>
                                            <Col style={{maxWidth: 150}}>
                                                <span style={{width: '100%'}}>{estado}</span>
                                            </Col>

                                            <Col style={{maxWidth: 150}}>
                                                <span style={{width: '100%'}}>{cidade}</span>
                                            </Col>
                                        </>
                                    )
                                }
                            </Row>
                        </>
                        )
                    }
                    

                    <Row>
                        <Col>
                            <Field 
                                label="Destino da viagem (separe os locais por vírgulas)"
                                name="destino"
                                placeholder="local 1, local 2, local 3, ..."
                                style={{ width: '100%' }}
                                error={formError.destino}
                            />
                        </Col>
                    </Row>


                    <Form.Group>
                        <Button appearance="primary" onClick={handleSubmit}>
                            Cadastrar
                        </Button>
                    </Form.Group>

                </Grid>
            </Form>
        </>
    )
}