import React, {useEffect} from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAuthContext } from "../Auth/useAuthContext";

interface IFormInput {
  firstName: string;
  lastName: string;
  age: number;
}

const HomePage: React.FC = () => {
  const { register, handleSubmit } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = data => console.log(data);

  const { user, showLoading, hideLoading } = useAuthContext();

  useEffect(() => {
    showLoading();
    
    setTimeout(() => {
      hideLoading();
    }, 3000); 

    return () => hideLoading();
  }, [showLoading, hideLoading]);

  console.log("Usuario: ", user)
  return (
        // <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        //   <form onSubmit={handleSubmit(onSubmit)}>
        //     <input {...register("firstName", { required: true, maxLength: 20 })} />
        //     <input {...register("lastName", { pattern: /^[A-Za-z]+$/i })} />
        //     <input type="number" {...register("age", { min: 18, max: 99 })} />
        //     <input type="submit" />
        //   </form>
          
        // </div>


        <></>

  );
};

export default HomePage;
