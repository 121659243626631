import html2pdf from 'html2pdf.js';
import React from 'react';
import { FaUser } from 'react-icons/fa';

interface Passageiro {
    id: string;
    nome: string;
    checkin: boolean;
    checkout: boolean;
    idViagem: number;
    sobrenome: string;
    identificacao: string;
    nacionalidade: string;
    emergencia_nome: string;
    emergencia_telefone: string;
    passaporte?: string;
    idAtividade?: string;
    cpf?: string;
    telefone?: string;
    cidade?: string;
    estado?: string;
    pais?: string;
    email?: string;
  }

interface ListaSimpleProps {
  data: Passageiro[];
  onItemClick: (item: Passageiro) => void;
  removePassageiro: (item: Passageiro) => void;
}

const ListaSimples: React.FC<ListaSimpleProps> = ({ data, onItemClick, removePassageiro }) => {


  async function generateFichaCadastroPDF(item: Passageiro): Promise<void> {
    const htmlContent = `
        <!DOCTYPE html>
        <html>
            <head>
                <meta charset="UTF-8">
                <style>
                    /* Estilo geral */
                    body {
                        font-family: Arial, sans-serif;
                        font-size: 12px;
                        margin: 0;
                        padding: 0;
                    }
                    .page {
                        width: 210mm;
                        height: 297mm;
                        padding: 20mm;
                        box-sizing: border-box;
                        border: 1px solid #ddd;
                    }
                    /* Cabeçalho com imagem e número do quarto */
                    .header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 20px;
                    }
                    .header img {
                        width: 100px;
                        height: auto;
                    }
                    .header .room-number {
                        font-size: 16px;
                        font-weight: bold;
                    }
                    /* Título da seção */
                    .section-title {
                        font-size: 14px;
                        font-weight: bold;
                        margin-top: 20px;
                        padding-bottom: 5px;
                        border-bottom: 1px solid #000;
                    }
                    /* Linhas de formulário */
                    .form-container {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        margin-top: 10px;
                    }
                    .form-row {
                        display: flex;
                        gap: 15px;
                    }
                    .form-item {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                    }
                    .form-item label {
                        font-weight: bold;
                        margin-bottom: 3px;
                    }
                    .form-item .input-box {
                        padding: 8px;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        background-color: #f4f4f4;
                        min-height: 30px; /* Altura mínima para os campos */
                    }
                    /* Opções de sexo */
                    .option-group {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        background-color: #f4f4f4;
                        padding: 8px;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                    }
                    /* Campo de assinatura */
                    .signature {
                        margin-top: 30px;
                        font-size: 14px;
                        text-align: center;
                        border-top: 1px solid #000;
                        padding-top: 10px;
                    }
                </style>
            </head>
            <body>
                <div class="page">
                    <!-- Cabeçalho com logo e número do quarto -->
                    <div class="header">
                        <div class="room-number">Sllips</div>
                        <div class="room-number">Número do Quarto: </div>
                    </div>

                    <!-- Formulário estruturado -->
                    <div class="form-container">
                        <div class="form-row">
                            <div class="form-item">
                                <label>Nome Completo:</label>
                                <div class="input-box">${item.nome} ${item?.sobrenome || ''}</div>
                            </div>
                            <div class="form-item">
                                <label>Telefone:</label>
                                <div class="input-box">${item?.telefone || ''}</div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-item">
                                <label>Profissão:</label>
                                <div class="input-box"></div>
                            </div>
                            <div class="form-item">
                                <label>Nacionalidade:</label>
                                <div class="input-box">${item?.nacionalidade || ''}</div>
                            </div>
                            <div class="form-item">
                                <label>Data de Nascimento:</label>
                                <div class="input-box"></div>
                            </div>
                            <div class="form-item">
                                <label>Sexo:</label>
                                <div class="option-group">
                                    <label><input type="radio" name="sexo" value="masculino" /> Masculino</label>
                                    <label><input type="radio" name="sexo" value="feminino" /> Feminino</label>
                                    <label><input type="radio" name="sexo" value="outros" /> Outros</label>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-item">
                                <label>Documento (Passaporte):</label>
                                <div class="input-box">${item?.identificacao || ''}</div>
                            </div>
                            <div class="form-item">
                                <label>CPF:</label>
                                <div class="input-box">${item?.cpf || ''}</div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-item">
                                <label>Cidade:</label>
                                <div class="input-box">${item?.cidade || ''}</div>
                            </div>
                            <div class="form-item">
                                <label>Estado:</label>
                                <div class="input-box">${item?.estado || ''}</div>
                            </div>
                            <div class="form-item">
                                <label>País:</label>
                                <div class="input-box">${item?.pais || ''}</div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-item">
                                <label>Email:</label>
                                <div class="input-box">${item?.email || ''}</div>
                            </div>
                        </div>
                        <div class="signature">
                            <div>Assinatura do Hóspede</div>
                        </div>
                    </div>
                </div>
            </body>
        </html>
    `;

    const element = document.createElement("div");
    element.innerHTML = htmlContent;
    document.body.appendChild(element);

    const options = {
        filename: `ficha_cadastro.pdf`,
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf().from(element).set(options).save();
    document.body.removeChild(element);
}


  return (
    <div style={{ marginTop: 20, flex: 1 }}>
      {
        data != null && data.length > 0 ? 
            (
                data.map((item) => (
                    <>
                        <div
                        key={item.identificacao}
                        style={{
                            borderBottom: '1px solid #c7c7c7',
                            flexDirection: 'row',
                            marginBottom: '3%',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            /*gap:'9px',*/
                        }}
                        
                        >
                            <div style={{ flex: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', borderRadius: '5rem', padding: '2rem', backgroundColor: '#222222' }}>
                                <FaUser size={50}  style={styles.icon} />
                            </div>
                            <div style={{ flex: 4, marginLeft: '4%', paddingBottom: 3,  width:'27rem'}}>
                                <p style={{  fontFamily: 'Poppins-Regular', color: '#222222', fontSize: 13, textTransform: 'capitalize' }}>
                                {item.nome} {item.sobrenome}
                                </p>
                                <p style={{fontFamily: 'Poppins-light', color: '#6D6D6D', fontSize: 11 }}>
                                {item.identificacao}
                                {item.passaporte}
                                </p>
                                <p style={{ fontFamily: 'Poppins-Regular', color: '#C13515', fontSize: 11 }}>Passageiro</p>
                            </div>

                            <div style={{padding: '10px'}}>

                                <div style={{display: "flex", flex: 2, alignItems: "center", justifyContent: "center"}}>
                                    <div  style={{
                                                    borderRadius: 7,
                                                    height: 30, 
                                                    width: 95, 
                                                    display: "flex",             // Adicionado para centralizar o conteúdo
                                                    alignItems: "center",         // Alinha verticalmente o conteúdo
                                                    justifyContent: "center",      // Alinha horizontalmente o conteúdo
                                                    backgroundColor: '#222222' ,
                                                    cursor: "pointer",

                                    }} 
                                    onClick={(item.checkout && item.checkout) ?  undefined : () => generateFichaCadastroPDF(item) }
                                    >

                                        <span style={{fontFamily: 'Poppins-Regular', color: '#fff', fontSize: 12 }}>Ficha</span>

                                    </div>
                                </div>

                                <div style={{display: "flex", flex: 2, alignItems: "center", justifyContent: "center", paddingTop: 20}}>
                                    <div  style={{
                                                    borderRadius: 7,
                                                    height: 30, 
                                                    width: 95, 
                                                    display: "flex",             // Adicionado para centralizar o conteúdo
                                                    alignItems: "center",         // Alinha verticalmente o conteúdo
                                                    justifyContent: "center",      // Alinha horizontalmente o conteúdo
                                                    backgroundColor: item.checkout ? '#e61e4d' : '#F1416C' ,
                                                    cursor: "pointer",

                                    }} 
                                    onClick={(item.checkout && item.checkout) ?  undefined : () => onItemClick(item) }
                                    >

                                        <span style={{fontFamily: 'Poppins-Regular', color: '#fff', fontSize: 12 }}>{item.checkout ? 'Ok' : 'Checkout'}</span>

                                    </div>
                                </div>

                                <div style={{display: "flex", flex: 2, alignItems: "center", justifyContent: "center", paddingTop: 20}}>
                                    <div  style={{
                                                    borderRadius: 7,
                                                    height: 30, 
                                                    width: 95, 
                                                    display: "flex",             // Adicionado para centralizar o conteúdo
                                                    alignItems: "center",         // Alinha verticalmente o conteúdo
                                                    justifyContent: "center",      // Alinha horizontalmente o conteúdo
                                                    backgroundColor: item.checkout ? '#e61e4d' : '#F1416C' ,
                                                    cursor: "pointer",

                                    }} 
                                    onClick={() => removePassageiro(item) }
                                    >

                                        <span style={{fontFamily: 'Poppins-Regular', color: '#fff', fontSize: 12 }}>Remover</span>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ))
            )
            :
            (
                <><p style={{color: "blue"}} >Não foram encontrados passageiros. Vá até QRCode para adicionar passageiros!</p></>
            )
      }
    </div>
  );
};


const styles = {
   
    icon: {
    
      color: '#ffffff',
    },
}

export default ListaSimples;
