import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { 
    Schema, 
    Form, 
    MaskedInput, 
    Message, 
    toaster, 
    Button, 
    Grid, 
    Row, 
    Col, 
    InputPicker, 
    TagPicker, 
} from "rsuite";
import { Field } from "../components/Input/Field";
import api from "../Services/axios";
const { StringType, ArrayType } = Schema.Types;

const funcoes = [
    'Atendente',
    'Marinheiro(a)',
    'Motorista',
].map(value => ({label: value, value: value}))

const habilitacaoVeiculo = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'Não possui'
].map(value => ({label: value, value: value}))

const habilitacaoEmbarcacao = [
    'Arrais Amador (AA)', 
    'Mestre Amador (MA)', 
    'Capitão Amador (CA)', 
    'Capitão de Cabotagem (CCT)', 
    'Capitão de Longo Curso (CLC)', 
    'Patrão de Embarcações de Pesca (PEP)',
    'Marinheiro Auxiliar de Convés (MAC)',
    'Não possui'
].map(value => ({label: value, value: value}))

export const CadastraColaborador = () => {
    const navigate = useNavigate();

    const formRef = useRef<any>();
    const [empresasSelecionaveis, setEmpresasSelecionaveis] = useState();
    const [funcao, setFuncao] = useState<string>('');
    const [formValue, setFormValue] = useState<{ 
            funcao: string; 
            nomeCompleto: string, 
            nCIR: string, 
            telefone: string, 
            habilitacaoVeiculo: string,
            habilitacaoEmbarcacao: string,
            email: string,
            senha: string,
            associadoEmpresas: number[]
        }>({
        funcao: '', 
        nomeCompleto: '', 
        nCIR: '', 
        telefone: '', 
        habilitacaoVeiculo: '',
        habilitacaoEmbarcacao: '',
        email: '',
        senha: '',
        associadoEmpresas: [],
    });


    useEffect(() => {
        const fetchData = async () =>{
            let response;
            try{
                response = await api.get('/empresa', { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }})

                if(response.status === 200){
                    setEmpresasSelecionaveis(response.data.map((item: any)=> ({label: item.rasao_social, value: item.id})))
                }
            }catch(error){  
                console.log(error);
            }
        }
        fetchData()
    }, [])

    useEffect(() => {
        console.log(formValue);
    }, [formValue])

    const [formError, setFormError] = useState<any>({});

    const handleFormChange = (formValue: Record<string, any>, event?: React.SyntheticEvent) => {
        setFormValue((prevState) => ({
            ...prevState,
            ...formValue,
        }));
    };

    const handleSubmit = async () => {
        if (!formRef.current.check()) {
          toaster.push(<Message type="error">Error</Message>);
          return;
        }
        
        try{
            let newUser = await api.post('/cadastre-se', {
                    nome: formValue.nomeCompleto,
                    segmento: formValue.funcao,
                    password: formValue.senha,
                    email: formValue.email
                },
            );

            if(newUser.status !== 201){
                console.log("Erro ao tentar criar usuário")
                return
            }
            const body = { ...formValue, user_id: newUser.data.id };
            console.log("Body enviado:", body);

            let response = await api.post('/colaborador', body,
                { headers: {"Authorization" : "Bearer " + localStorage.getItem("accessToken"), "Content-Type": "application/json"}});
    
            if(response.status === 201) {
                setFormValue({
                    funcao: '', 
                    nomeCompleto: '', 
                    nCIR: '', 
                    telefone: '', 
                    habilitacaoVeiculo: '',
                    habilitacaoEmbarcacao: '',
                    email: '',
                    senha: '',
                    associadoEmpresas: []
                })
                navigate(-1);
            }

        }catch(error){
            console.log(error);
        }
    };

    const model = Schema.Model({
        funcao: StringType().isRequired('Campo obrigatório'), 
        nomeCompleto: StringType().isRequired('Campo obrigatório'), 
        nCIR: StringType(), 
        telefone: StringType().isRequired('Campo obrigatório'), 
        habilitacaoVeiculo: StringType(),
        habilitacaoEmbarcacao: StringType(),
        email: StringType().isEmail('Insira um e-mail válido').isRequired('Campo obrigatório'),
        senha: StringType().isRequired('Campo obrigatório'),
        associadoEmpresas: ArrayType().minLength(1).isRequired('Campo obrigatório')
    });

    return (
        <>
            <Form
                ref={formRef}
                onChange={handleFormChange}
                onCheck={setFormError}
                formValue={formValue}
                model={model}
            >
                <Grid fluid>
                    {/* Primeira Linha */}
                    <Row>
                        <Col style={{maxWidth: 300}}>
                            <Field
                                label="Função"
                                name="funcao"
                                data={funcoes}
                                style={{width: '100%'}}
                                onChange={(value: string) => setFuncao(value)}
                                error={formError.funcao}
                                accepter={InputPicker}
                            />
                        </Col>
                        <Col style={{maxWidth: 300}}>
                            <Field
                                label="Nome completo"
                                name="nomeCompleto"
                                style={{width: '100%'}}
                                error={formError.nomeCompleto}
                            />
                        </Col>
                    </Row>

                    {/* Segunda Linha */}
                    {
                        funcao === 'Marinheiro(a)' && (
                            <Row>
                                <Col style={{maxWidth: 300}}>
                                    <Field 
                                        label="Número do CIR"
                                        name="nCIR"
                                        style={{width: '100%'}}
                                        error={formError.nCIR}
                                    />
                                </Col>
                                
                                <Col style={{maxWidth: 300}}>
                                    <Field 
                                        label="Habilitação da marinha"
                                        accepter={InputPicker}
                                        data={habilitacaoEmbarcacao}
                                        name="habilitacaoEmbarcacao"
                                        style={{width: '100%'}}
                                        error={formError.habilitacaoEmbarcacao}
                                    />
                                </Col>
                            </Row>
                        )
                    }

                    {/*Terceira Linha */}    
                    {
                        funcao === 'Motorista' && ( 
                            <Row>
                                <Col style={{maxWidth: 300}}>
                                    <Field 
                                        label="Categoria de habilitação"
                                        name="habilitacaoVeiculo"
                                        data={habilitacaoVeiculo}
                                        accepter={InputPicker}
                                        style={{width: '100%'}}
                                        error={formError.habilitacaoVeiculo}
                                    />
                                </Col>
                            </Row>
                        )
                    }       
                    <Row>
                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Telefone (whastapp)"
                                name="telefone"
                                accepter={MaskedInput}
                                mask={[/\d/,/\d/,' ',/\d/,/\d/,/\d/,/\d/,/\d/,"-", /\d/,/\d/,/\d/,/\d/,]}
                                placeholder="DD 00000-0000"
                                style={{width: '100%'}}
                                error={formError.telefone}
                            />
                        </Col>
                        
                    </Row>
                
                    <Row>
                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Email"
                                name="email"
                                style={{width: '100%'}}
                                error={formError.email}
                            /> 
                        </Col>

                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Senha"
                                name="senha"
                                style={{width: '100%'}}
                                error={formError.senha}
                            /> 
                        </Col>
                    </Row>

                    <Row >
                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Empresas associadas a este colaborador"
                                name="associadoEmpresas"
                                accepter={TagPicker}
                                style={{minWidth: 200}}
                                data={empresasSelecionaveis}
                                error={formError.associadoEmpresas}
                            />
                        </Col>
                    </Row>

                    <Form.Group>
                        <Button appearance="primary" onClick={handleSubmit}>
                            Cadastrar
                        </Button>
                    </Form.Group>

                </Grid>
            </Form>
        </>
    )
}