import "./../card/card.css"

const DuracaoMediaEstadia = ({mediaEstadia}: any) => {

    return (
        <>
            <div className="deposit-card" style={{width: '17%'}}>
                <div className="" style={{display: 'flex', flexDirection: 'column', height: '100%', gap: '3rem'}}>
                    <div className="deposit-header">
                        <h3 style={{color: '#8094AE', fontSize: '1.6rem'}}>Média de diárias</h3>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column'}}>
                        <h3 style={{fontSize: '4rem', fontFamily: 'Poppins-Light', color: '#526484'}}>{ Math.round(mediaEstadia) } diárias</h3>
                        <p style={{color: '#526484', fontFamily: 'Poppins-Light', fontSize: '1.6rem', textAlign:'center'}}>Duração média da estadia dos turistas.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DuracaoMediaEstadia;