interface LocaisProps {
  title: string,
  data: LocaisContagem[],
  showLocation: boolean
}

type LocaisContagem = {
  local: string;
  estado?: string;
  contagem: number;
}

export const Locais = ({title, data, showLocation}: LocaisProps) => {
  
    return (    
      <div className="" style={{width: '30%', borderRadius: '10px'}}>
        <div style={{height: '40vh', overflow: 'scroll', borderRadius: '10px'}} className="card ht-100p">
          <div className="card-header d-flex align-items-center justify-content-between pd-r-10">
            <h6 className="mg-b-0" style={{color: '#8094AE'}}>{title} que mais nos visitam</h6>
          </div>
          <ul className="list-group list-group-flush tx-13">
            { 
              data?.map((item, key) => {
                return (
                  <li key={key} className="list-group-item d-flex pd-sm-x-20">
                    <div  className="avatar d-none d-sm-block">
                      <span className="avatar-initial rounded-circle" style={{backgroundColor: '#1EE0AC'}}></span>
                    </div>
                    <div  className="pd-sm-l-10">
                      <small  className="tx-12 tx-semibold mg-b-0" style={{color: '#364a63', }}>{ item.local }</small>
                      <br  />
                      {
                        showLocation && (
                          <small  className='tx-light' style={{color: '#526484', fontFamily: 'Poppins-Light'}}>
                            {item.estado ?? 'Brasil'}
                          </small>
                        )
                      }
                    </div>
                    <div   className="mg-l-auto text-end">
                      <small  className="tx-12 mg-b-0 tx-bold" style={{color: '#364a63'}}>{ item.contagem }</small>
                      <br  />
                      <small className="tx-12 tx-success mg-b-0" style={{color: '#526484', fontFamily: 'Poppins-Light'}}>Visitantes</small>
                    </div>
                  </li>
                )
              })
            }
          </ul>
          </div>
        </div>
    )
}
