import { useEffect, useState } from "react";
import { Button, Nav } from "rsuite"
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import { useNavigate } from 'react-router-dom';
import api from "../Services/axios";
import { useAuthContext } from "../Auth/useAuthContext";
import { FaBuilding, FaCar, FaPlane, FaShip, FaUser } from "react-icons/fa";

export const Admin: React.FC = () => {
    const { user } = useAuthContext();
    const [navSelected, setNavSelected] = useState<string | number>('Veiculos');
    const [empresas, setEmpresas] = useState<any>([]);
    const [veiculos, setVeiculos] = useState<any>([]);
    const [colaboradores, setColaboradores] = useState<any>([]);
    const navigate= useNavigate();

    useEffect(() => {
        let response;

        const fetchData = async () => {
            try {
                response = await api.get(`/colaboradorempresa/${user?.id}`, 
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }})

                if(response.status === 200){
                    setEmpresas(response.data);
                }
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, [])

    useEffect(() => {
        let response;

        const fetchData = async () => {
            try {
                response = await api.get(`/veiculos/empresa/${user?.id}`, 
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }})
                
                if(response.status === 200){
                    setVeiculos(response.data);
                }

            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, [])

    useEffect(() => {
        
        const fetchData = async () => {
            try{
                let response = await api.get('/colaborador/all', 
                    { headers: { 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") }})
                
                console.log(response.data);

                if(response.status === 200){
                    setColaboradores(response.data);
                }
            }catch(error){
                console.log(error);
            }
        }

        fetchData();
    }, [])


    return (
        <div style={{maxWidth: '100%', margin: 'auto', position: 'relative'}}>
            <Nav style={{width: '100%'}} onSelect={(value) => setNavSelected(value)} appearance="subtle" justified defaultActiveKey="Home">
                <Nav.Item  eventKey="Empresas">Empresa</Nav.Item>
                <Nav.Item  eventKey="Veiculos">Veículos</Nav.Item>
                <Nav.Item  eventKey="Colaboradores">Colaboradores</Nav.Item>
            </Nav>
            {
                navSelected === 'Veiculos' && (
                    <>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '2rem'}}>
                            <div style={{display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'space-evenly', marginTop: '1rem'}}>
                                {
                                    veiculos.length > 0 && veiculos.map((item: any) => (
                                        <>
                                            {
                                                item.Veiculos.length > 0 && item.Veiculos.map((item2: any, index: number) => (
                                                    <>
                                                        <div
                                                            key={item.id}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                padding: '15px',
                                                                width: '100%',
                                                                border: '1px solid rgb(199, 199, 199)',
                                                                borderRadius: '8px',
                                                                backgroundColor: '#fff',
                                                                cursor: 'pointer',
                                                            }} 
                                                        >
                                                            {
                                                                ['Lancha','Embarcação','LANCHA - MOTORBOAT', 'CATAMARÃ', 'LANCHA'].includes(item2.tipoVeiculo) && (
                                                                    <>
                                                                        <FaShip size={24} style={{
                                                                            margin:'2rem',
                                                                            color: '#222222',
                                                                        }} />
                                                                    </>
                                                                )
                                                            }

                                                            {
                                                                ['Condução', '4x4', 'Automotivo'].includes(item2.tipoVeiculo) && (
                                                                    <>
                                                                        <FaCar size={24} style={{
                                                                            margin:'2rem',
                                                                            color: '#222222',
                                                                        }} />
                                                                    </>
                                                                )
                                                            }

                                                            {
                                                                ['Aéreo'].includes(item2.tipoVeiculo) && (
                                                                    <>
                                                                        <FaPlane size={24} style={{
                                                                            margin:'2rem',
                                                                            color: '#222222',
                                                                        }} />
                                                                    </>
                                                                )
                                                            }
                                                            <div>
                                                            <h3 style={{
                                                                color: '#333333',
                                                                margin: '0',
                                                                fontSize: '14px',
                                                                fontFamily: 'Poppins-semiBold',
                                                            }}>{item2.nome}</h3>
                                                            </div>
                                                        </div>
                                                    
                                                    </>
                                                ))
                                            }
                                        </>
                                    ))
                                }
                            </div>
                            <Button 
                                style={{
                                    display: 'inline', 
                                    backgroundColor: '#01b8bc', 
                                    color: 'white'
                                }} 
                                onClick={(e) => navigate('NovoVeiculo')}>
                                Cadastrar novo veículo
                            </Button>
                        </div>     
                    </>
                )
            }

            {
                navSelected === 'Colaboradores' && (
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '2rem', marginTop: '1rem'}}>
                        {
                            colaboradores.length > 0 && colaboradores.map((item: any, index: number) => (
                                <>
                                    <div
                                        key={item.id}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '15px',
                                            border: '1px solid rgb(199, 199, 199)',
                                            borderRadius: '8px',
                                            backgroundColor: '#fff',
                                            cursor: 'pointer',
                                        }} 
                                    >
                                        <FaUser size={24} style={{
                                            margin:'2rem',
                                            color: '#222222',
                                        }} />
                                        <div>
                                        <h3 style={{
                                            color: '#333333',
                                            margin: '0',
                                            fontSize: '14px',
                                            fontFamily: 'Poppins-semiBold',
                                        }}>{item.nome}</h3>
                                        </div>
                                    </div>
                                </>
                            ))
                        }
                        <Button 
                            style={{
                                display: 'inline', 
                                backgroundColor: '#01b8bc', 
                                color: 'white'
                            }} 
                            onClick={(e) => navigate('NovoColaborador')} 
                            >
                                Cadastrar novo colaborador
                            </Button>
                    </div>
                )
            }

            {
                navSelected === 'Empresas' && (
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '2rem'}}>
                        <div style={{display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'space-evenly', marginTop: '1rem'}}>
                            {
                                empresas[0].Empresas.length > 0 && empresas[0].Empresas.map((item: any, index: number) => (
                                    <div
                                        key={item.id}
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            alignItems: 'center',
                                            padding: '15px',
                                            border: '1px solid rgb(199, 199, 199)',
                                            borderRadius: '8px',
                                            backgroundColor: '#fff',
                                            cursor: 'pointer',
                                        }} 
                                    >
                                        <FaBuilding size={24} style={{
                                            margin:'2rem',
                                            color: '#222222',
                                        }} />
                                        <div>
                                        <h3 style={{
                                            color: '#333333',
                                            margin: '0',
                                            fontSize: '14px',
                                            fontFamily: 'Poppins-semiBold',
                                        }}>{item.rasao_social}</h3>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <Button 
                            style={{
                                display: 'inline', 
                                backgroundColor: '#01b8bc', 
                                color: 'white'
                            }} 
                            onClick={(e) => navigate('NovaEmpresa')}>
                            Cadastrar nova empresa
                        </Button>
                    </div>
                )
            }
        </div>
    )
}
