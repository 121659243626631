import graficoAzul from "../../../assets/image/AZUL GRAFICO.svg";
import graficoVerde from "../../../assets/image/VERDE GRAFICO.svg";

interface AgrupamentoPorIdadeProps {
    menores: number;
    adultos: number;
    meiaIdade: number;
    idosos: number;
    taxas: {
        menoresTaxa:number;
        adultosTaxa: number;
        meiaIdadeTaxa: number;
        idososTaxa:number;
    }
}

export const FaixaEtaria = ({ menores, adultos, meiaIdade, idosos, taxas }:AgrupamentoPorIdadeProps) => {
    return (
        <>
            <div
                style={{
                    display: 'flex', 
                    padding: '1rem', 
                    flexDirection: 'column', 
                    border: '1px solid #e5e8eb', 
                    backgroundColor: 'white', 
                    position:'relative', 
                    overflow: 'hidden', 
                    borderRadius: '10px', 
                    width:'18%',
                    justifyContent: 'space-evenly'
                }}>

                
                <h3 style={{fontSize: '1.6rem', color: '#8094AE'}}>Faixa etária</h3>
                

                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: '4rem'}}>
                    
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '45%'}}>
                            <span style={{fontSize: '1.6rem', color: '#8094AE'}}>DE 0-17</span>
                            <img src={graficoAzul} alt="grafico azul" />
                        </div>
                
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'end'}}>
                            <span color="#364a63">{ menores }</span>
                            {
                                taxas.menoresTaxa < 0 ?
                                (
                                    <>
                                        <span style={{color: "#e85347"}}>↓ {taxas.menoresTaxa.toFixed(0)}%</span>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <span style={{color: "#1EE0AC"}}>↑ {taxas.menoresTaxa.toFixed(0)}%</span>
                                    </>
                                )
                            }
                            <span style={{color: '#8094ae'}}>vs. mês anterior</span>
                        </div>
                    </div>


                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '45%'}}>
                            <span style={{fontSize: '1.6rem', color: '#8094AE'}}>DE 18-34</span>
                            <img src={graficoVerde} alt="grafico azul" />
                        </div>
                        

                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'end'}}>
                            <span color="#364a63">{ adultos }</span>
                            {
                                taxas.adultosTaxa < 0 ?
                                (
                                    <>
                                        <span style={{color: "#e85347"}}>↓ {taxas.adultosTaxa.toFixed(0)}%</span>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <span style={{color: "#1EE0AC"}}>↑ {taxas.adultosTaxa.toFixed(0)}%</span>
                                    </>
                                )
                            }
                            <span style={{color: '#8094ae'}}>vs. mês anterior</span>
                        </div>
                    </div>


                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '45%'}}>
                            <span style={{fontSize: '1.6rem', color: '#8094AE'}}>DE 35-49</span>
                            <img src={graficoAzul} alt="grafico azul" />
                        </div>
                        

                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'end'}}>
                            <span color="#364a63">{ meiaIdade }</span>
                            {
                                taxas.meiaIdadeTaxa < 0 ?
                                (
                                    <>
                                        <span style={{color: "#e85347"}}>↓ {taxas.meiaIdadeTaxa.toFixed(0)}%</span>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <span style={{color: "#1EE0AC"}}>↑ {taxas.meiaIdadeTaxa.toFixed(0)}%</span>
                                    </>
                                )
                            }
                            <span style={{color: '#8094ae'}}>vs. mês anterior</span>
                        </div>
                    </div>


                    <div  style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '45%'}}>
                            <span style={{fontSize: '1.6rem', color: '#8094AE'}}>ACIMA DE 50</span>
                            <img src={graficoVerde} alt="grafico azul" />
                        </div>
                    
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'end'}}>
                            <span color="#364a63">{ idosos }</span>
                            {
                                taxas.idososTaxa < 0 ?
                                (
                                    <>
                                        <span style={{color: "#e85347"}}>↓ {taxas.idososTaxa.toFixed(0)}%</span>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <span style={{color: "#1EE0AC"}}>↑ {taxas.idososTaxa.toFixed(0)}%</span>
                                    </>
                                )
                            }
                            <span style={{color: '#8094ae'}}>vs. mês anterior</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}