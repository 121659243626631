interface RecorrenciaProps {
    primeiraVisita: number;
    retorno: number;
    taxaPrimeiraVisita: number;
    taxaRetorno: number;
}

export const Recorrencia = ({primeiraVisita, retorno, taxaPrimeiraVisita, taxaRetorno}: RecorrenciaProps) =>{
    return (
        <>
            <div style={{
                    display: 'flex', 
                    paddingLeft: '3rem', 
                    flexDirection: 'column', 
                    border: '1px solid #e5e8eb', 
                    backgroundColor: 'white', 
                    position:'relative', 
                    overflow: 'hidden', 
                    borderRadius: '10px', 
                    width:'15%',
                    justifyContent: 'space-evenly'
                }}>
                <div style={{position: 'absolute', left: '0', backgroundColor: '#EE4D88', height: '100%', width: '15px'}} />

                <h3 style={{fontSize: '1.6rem', color: '#8094AE'}}>Recorrencia</h3>
                <div>
                    <span style={{color: '#8094AE', fontSize: '1.6rem'}}>PRIMEIRA VISITA</span>
                    <div>
                        <span style={{color: '#364a63', fontSize: '2rem' , fontFamily: 'Poppins-Light'}}>{primeiraVisita} 
                        <span style={{color: '#526484', fontFamily: 'Poppins-Light'}}> pessoas</span></span>
                        {
                            taxaPrimeiraVisita < 0 ?
                            (
                                <span style={{color: "#e85347"}}>↓ {taxaPrimeiraVisita.toFixed(0)}%</span>
                            )
                            :
                            (

                                <span style={{color: "#1EE0AC"}}>↑ {taxaPrimeiraVisita.toFixed(0)}%</span>
                            )
                        }
                    </div>
                </div>

                <div>
                    <span style={{color: '#8094AE', fontSize: '1.6rem'}}>RETORNO</span>
                    <div>
                        <span style={{color: '#364a63', fontSize: '2rem', fontFamily: 'Poppins-Light'}}>{retorno} 
                        <span style={{color: '#526484', fontFamily: 'Poppins-Light'}}> pessoas</span></span>
                        {
                            taxaRetorno < 0 ?
                            (
                                <span style={{color: "red"}}>↓ {taxaRetorno.toFixed(0)}%</span>
                            )
                            :
                            (

                                <span style={{color: "#1EE0AC"}}>↑ {taxaRetorno.toFixed(0)}%</span>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}