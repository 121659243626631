import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; 
import './Tabs.css'; 
import Atividades from '../../pages/AtividadePage';
import QrCode from '../../pages/QrcodePage';
import {Admin as AdminPage} from '../../pages/Admin';
import { useAuthContext } from '../../Auth/useAuthContext';
import Atividade_Novo from "../../assets/image/atividade_novo.svg";
import QRCode from "../../assets/image/qrcode.svg";
import Perfil from "../../assets/image/usuario.svg";
import Dashboard from '../../assets/image/dashb.svg';
import Admin from "../../assets/image/admin.svg";
import { useNavigate } from 'react-router-dom';

interface Tab {
  label: string;
  icon: JSX.Element;
  path: string; // Adiciona o caminho da rota
}


const Tabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const location = useLocation();
  const navigate = useNavigate();

  const {user} = useAuthContext();

  const renderTabContentAtendente = () => {
    switch (activeTab) {
      case 0:
        return <Atividades />
      case 1:
        return <QrCode />;
      case 2:
        return <AdminPage />;
      case 3:
        return <div>Conteúdo do Perfil</div>;
      default:
        return null;
    }
  };

  const renderTabContentColaborador = () => {
    switch (activeTab) {
      case 0:
        return <Atividades />
      case 1:
        return <QrCode />;
      case 2:
        return <div>Conteúdo do Perfil</div>;
      default:
        return null;
    }
  };

  const renderTabContentEmpresa = () => {
    switch (activeTab) {
      case 0:
        return <Atividades />
      case 1:
        return <AdminPage />;
      case 2:
        return <div>Conteúdo do Perfil</div>;
      default:
        return null;
    }
  };

  const renderTabContentPrefeitura = () => {
    switch (activeTab) {
      case 0:
        return <Atividades />
      case 1:
        return <AdminPage />;
      case 2:
        return <div>Conteúdo do Perfil</div>;
      default:
        return null;
    }
  };

  
  const tabsAtendente: Tab[] = [
    { label: 'Atividade', icon: <img src={Atividade_Novo}  alt="Atividade"/>, path: '/Empresa/Atividades' },
    { label: 'QR Code', icon: <img src={QRCode} alt="QR Code" />, path: '/Empresa/QRCode' },
    { label: 'Admin', icon: <img src={Admin} alt="Administrador" />, path: '/Empresa/Admin' },
    { label: 'Perfil', icon: <img src={Perfil} alt="Usuário" />, path: '/Empresa/Perfil' },
  ];

  const tabsEmpresa: Tab[] = [
    { label: 'Atividade', icon: <img src={Atividade_Novo}  alt="Atividade"/>, path: '/Empresa/Atividades' },
    { label: 'Admin', icon: <img src={Admin} alt="Administrador" />, path: '/Empresa/Admin' },
    { label: 'Perfil', icon: <img src={Perfil} alt="Usuário" />, path: '/Empresa/Perfil' },
    { label: 'Dashboard', icon: <img src={Dashboard} alt="Dashboard" />, path: '/visitantes' },
  ];

  const tabsColaborador: Tab[] = [
    { label: 'Atividade', icon: <img src={Atividade_Novo}  alt="Atividade"/>, path: '/Empresa/Atividades' },
    { label: 'QR Code', icon: <img src={QRCode} alt="Usuário" />, path: '/Empresa/Qrcode' },
    { label: 'Perfil', icon: <img src={Perfil} alt="Usuário" />, path: '/Empresa/Perfil' },
  ];

  const tabsPrefeitura: Tab[] = [
    { label: 'Atividade', icon: <img src={Atividade_Novo}  alt="Atividade"/>, path: '/Empresa/Atividades' },
    { label: 'Perfil', icon: <img src={Perfil} alt="Usuário" />, path: '/Empresa/Perfil' },
    { label: 'Dashboard', icon: <img src={Dashboard} alt="Dashboard" />, path: '/visitantes' },
  ];

  const [isMobile, setIsMobile] = useState(false);
  
  // Verifica se a tela é pequena
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Checar no carregamento inicial
    window.addEventListener("resize", handleResize); // Listener para mudanças de tamanho

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleTabClick = (index: number, path: string) => {
    setActiveTab(index);
    navigate(path); 
  };


  return (
    <div style={{ display: 'block', width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center'}}>

      <div>
        
        {
          (!location.pathname.includes('/Admin') || !location.pathname.includes('/Empresa')) && (
            <div className="container-tabs">
              <div style={{ marginBottom:'2rem', display: 'flex', flexDirection: 'column', justifyContent: 'start', width: '100%'}}>
                <div className='bemVindo'>Bem vindo, {user?.nome}!</div>
                <div style={{ fontSize: '13px', fontFamily: 'Poppins-Regular', color: '#222222' }}>Vamos oferecer experiências incríveis!</div>
              </div>
            </div>
          )
        }

        <div style={{display: 'flex', justifyContent: 'center'}}> 
        {
          isMobile && (
            <>
              {
                (user?.segmento === "Marinha" || user?.segmento === "Colaborador") && (
                  <>
                    {tabsColaborador.map((tab, index) => (
                      <div
                        key={index}
                        className={`tab ${activeTab === index ? 'active' : ''}`}
                        onClick={() => handleTabClick(index, tab.path)} 
                      >
                        {tab.icon}
                        <span>{tab.label}</span>
                      </div>
                    ))}
                  </>
                )
              }
              {
                (user?.segmento === "Atendente") && (
                  <>
                    {tabsAtendente.map((tab, index) => (
                      <div
                        key={index}
                        className={`tab ${activeTab === index ? 'active' : ''}`}
                        onClick={() => handleTabClick(index, tab.path)} 
                      >
                        {tab.icon}
                        <span>{tab.label}</span>
                      </div>
                    ))}
                  </>
                )
              }
              {
                (user?.segmento === "Empresa") && (
                  <>
                    {tabsEmpresa.map((tab, index) => (
                        <div
                          key={index}
                          className={`tab ${activeTab === index ? 'active' : ''}`}
                          onClick={() => handleTabClick(index, tab.path)} 
                        >
                          {tab.icon}
                          <span>{tab.label}</span>
                        </div>
                        )
                      )
                    }
                  </>
                )
              }
            </>
          )
        }  
        </div>

        <div className="tab-content">
          {
            user?.segmento === "Atendente" && (
              <>{renderTabContentAtendente()}</>
            )
          }

          {
            (user?.segmento === "Colaborador" || user?.segmento === "Marinha") && (
              <>{renderTabContentColaborador()}</>
            )
          }

          {
            user?.segmento === "Empresa" && (
              <>{renderTabContentEmpresa()}</>
            )
          }

          {
            user?.segmento === "Prefeitura" && (
              <>{renderTabContentPrefeitura()}</>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Tabs;
