import { Form } from 'rsuite';
import { forwardRef } from 'react';

interface FieldProps {
  name: string;
  message?: string;
  label: string;
  error?: string;
  accepter?: any;
  children?: any;
  mask?: Object
  style?: any
  inline?: any
  onChange?: any
  onSelect?: any
  showMask?: boolean
  data?: any
  value?: any
  formatter?: any
  placeholder?: string
  format?: string
  disabled?: boolean
  decimalSeparator?: any
  lg?: any
  block?: any
  as?: any
}

export const Field = forwardRef((props: FieldProps, ref: any, children?: any) => {
    const { name, message, label, accepter, error, ...rest } = props;
    return (
      <Form.Group controlId={`${name}-10`} ref={ref} className={error ? 'has-error' : ''}>
        <Form.ControlLabel style={{fontSize: '1.4rem'}}>{label}</Form.ControlLabel>
        <Form.Control name={name} accepter={accepter} errorMessage={error} {...rest} />
        <Form.HelpText>{message}</Form.HelpText>
      </Form.Group>
    );
}); 