import paisesData from "./../assets/data/paises.json";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Schema, Form, MaskedInput, RadioGroup, Radio, Message, toaster, Button, Grid, Row, Col, InputPicker, TagPicker, InputNumber } from "rsuite";
import { Field } from "../components/Input/Field";
import axios from "axios";
import api from "../Services/axios";
const { StringType, ArrayType, NumberType } = Schema.Types;

function cpfCnpjValidation(value: string, tipoId: string){
    if(tipoId === 'CNPJ'){
        
        return true;
    }else{
        
        return true;
    }
}

function toThousands(value: string) {
    return value 
      ? `R$ ${`${value}`.replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')}` 
      : value;
  };

const nichos = [
    'Turismo Aquático',
    'Turismo Aéreo',
    'Turismo Terrestre',
    'Atrativos Turísticos',
    'Hospedagem',
    'Alimentício'
].map(value => ({label: value, value: value}))

const semNicho = [
    'Escolha um nicho'
].map(value => ({label: value, value: value}))

const atividadesAquaticas = [
    'Passeios de Barco/Lancha',
    'Esporte Aquáticos',
    'Pesca Esportiva',
    'Rafting Banho',
    'Natação',
    'Mergulho'
].map(value => ({label: value, value: value}))

const atividadesAereas = [
    'Passeios de Balão',
    'Parapente',
    'Paramotor',
    'Paraquedismo',
    'Voos Paranorâmicos',
    'Asa-Delta',
    'Helicóptero',
    'Salto Base',
    'Skydiving Indoor'
].map(value => ({label: value, value: value}))

const atividadesTerrestres = [
    'Passeios Off-Road',
].map(value => ({label: value, value: value}))

const atividadesTuristicas = [
    'Atrativos Naturais',
    'Atrativos Culturais',
    'Atrativos Históricos',
    'Atrativos Recreativos',
    'Atrativos Religiosos',
    'Atrativos Esportivos',
    'Atrativos Gastronômicos',
    'Atrativos Industriais',
].map(value => ({label: value, value: value}))

const atividadesHospedagem = [
    'Agriturismo',
    'Aluguéis de Temporada',
    'Apart-Hotéis',
    'Bangalôs',
    'Bed and Breakfast (B&B)',
    'Cabanas',
    'Campings',
    'Casas de Campo e Quintas',
    'Castelos',
    'Chalés',
    'Dormitórios Compartilhados',
    'Glampings',
    'Hostels (Albergues)',
    'Hostels Boutique',
    'Hostels Cápsula',
    'Hotéis',
    'Hotéis Boutique',
    'Hotéis de Luxo',
    'Hotéis de Negócios',
    'Hotéis Econômicos',
    'Palácios',
    'Pousadas',
    'Pousadas de Charme',
    'Pousadas Econômicas',
    'Quartos Privativos',
    'Residências Temporárias',
    'Resorts'
].map(value => ({label: value, value: value}))

const atividadesAlimenticias = [
    'Bistrôs',
    'Buffet Self-Service',
    'Cadeias de Fast Food',
    'Cafeteria',
    'Churrascarias',
    'Food Trucks',
    'Lanchonetes Pizzaria',
    'Pubs e Gastropubs',
    'Quiosques de Comida',
    'Rodízio',
    'Restaurantes à Beira-Mar',
    'Restaurantes Casual Dining',
    'Restaurantes com Show Cooking',
    'Restaurantes de Fusão',
    'Restaurantes Estrela Michelin',
    'Restaurantes Franceses',
    'Restaurantes Gourmet',
    'Restaurantes Italianos',
    'Restaurantes Japoneses',
    'Restaurantes Mexicanos',
    'Restaurantes Pop-up',
    'Restaurantes Temáticos',
    'Restaurantes Típicos',
    'Restaurantes Veganos',
    'Restaurantes Vegetarianos',
    'Tascas'
].map(value => ({label: value, value: value}))

export const CadastraEmpresa = () => {
    const navigate = useNavigate();

    const formRef = useRef<any>();
    const [tipoDocumento, setDocumento] = useState('CNPJ');
    const [cep, setCEP] = useState<string>('');
    const [paises, setPaises] = useState([{}]);
    const [nichoEscolhido, setNichoEscolhido] = useState('');
    const [formValue, setFormValue] = useState<{ 
            tipoId: string; 
            documento: string, 
            razaoNome: string, 
            nicho: string, 
            atividade: Array<string>,
            cep: string,
            pais: string,
            estado: string,
            cidade: string,
            endereco: string,
            numero: string,
            qtdeMaxHospedes: number,
            qtdeQuartos: number
            telefone: string,
            email: string,
            qtdeMaxPessoasEspaco: number,
            qtdeCachoeiras: number,
            valorMedio: string
        }>({
        tipoId: 'CNPJ',
        documento: '',
        razaoNome: '',
        nicho: '',
        atividade: [],
        cep: '',
        pais: 'BR',
        estado: '',
        cidade: '',
        endereco: '',
        numero: '',
        telefone: '',
        email: '',
        qtdeMaxHospedes: 0,
        qtdeQuartos: 0,
        qtdeMaxPessoasEspaco: 0,
        qtdeCachoeiras: 0,
        valorMedio: ''
    });

    useEffect(() => {
        const fetchPaises = async () =>{
          try {
            const paisesFormatados = paisesData.map((pais) => ({
              label: pais.translations.por?.common || pais.name.common, 
              value: pais.cca2, 
            }));
    
            setPaises(paisesFormatados);
          } catch (error) {
            console.log(error);
          }
        }
    
        fetchPaises();
      }, []);

    useEffect(() => {
        const fetchCEPData = async () => {
            let response, cidade: string, estado: string;
            const cepLimpo = cep.replace(/[.\-/_]/g, "");

            if(cepLimpo.length > 7){
                try{
                    response = await axios.get(`https://brasilapi.com.br/api/cep/v2/${cepLimpo}`);

                    if(response.status === 200){
                        cidade = response.data.city;
                        estado = response.data.state;

                        setFormValue((prev) => ({
                            ...prev,
                            cidade: cidade,
                            estado: estado
                        }))
                    }
                }catch(error){
                    console.log(error);
                }
            }
        }

        fetchCEPData();
    }, [cep])

    const [formError, setFormError] = useState<any>({
        tipoId: '',
        documento: '',
    });

    const handleFormChange = (formValue: Record<string, any>, event?: React.SyntheticEvent) => {
        setFormValue((prevState) => ({
            ...prevState,
            ...formValue,
        }));
    };

    const handleSubmit = async () => {
        if (!formRef.current.check()) {
          toaster.push(<Message type="error">Error</Message>);
          return;
        }
        
        try{
            let response = await api.post('/empresa', { formValue },
                { headers: {"Authorization" : "Bearer " + localStorage.getItem("accessToken")}});
    
            if(response.status === 200) {
                navigate(-1)
            }
        }catch(error){
            console.log(error);
        }
    };


    const model = Schema.Model({
        documento: StringType()
        .addRule((value) => cpfCnpjValidation(value, tipoDocumento), `Por favor, insira um ${tipoDocumento} válido.`)
        .isRequired('O campo é obrigatório.'),
        tipoId: StringType().isOneOf(['CPF', 'CNPJ']).isRequired('Escolha uma opção'),
        razaoNome: StringType().isRequired('Campo obrigatório!'),
        nicho: StringType().isRequired('Selecione uma opção'),
        atividade: ArrayType().isRequired('Selecione uma opção'),
        cep: StringType().isRequired('Campo obrigatório!'),
        pais: StringType().isRequired('Campo obrigatório!'),
        cidade: StringType().isRequired('Campo obrigatório!'),
        estado: StringType().isRequired('Campo obrigatório!'),
        endereco: StringType().isRequired('Campo obrigatório!'),
        numero: StringType().isRequired('Campo obrigatório!'),
        telefone: StringType().isRequired('Campo obrigatório!'),
        email: StringType().isEmail('Deve ser um e-mail válido').isRequired('Campo obrigatório!'),
        qtdeMaxHospedes: NumberType(),
        qtdeQuarto: NumberType(),
        qtdeMaxPessoasEspaco: NumberType(),
        qtdeCachoeiras: NumberType(),
        valorMedio: StringType().isRequired('Campo obrigatório')
    });

    return (
        <>
            <Form
                ref={formRef}
                onChange={handleFormChange}
                onCheck={setFormError}
                formValue={formValue}
                model={model}
            >
                <Grid fluid>
                    {/* Primeira Linha */}
                    <Row>
                        <Col style={{maxWidth: 300}}>
                            <Field
                                    name="tipoId"
                                    label="Documentação"
                                    error={formError.tipoId}
                                    accepter={RadioGroup}
                                    onChange={(value: string) => setDocumento(value)}
                                    inline
                                >
                                    <Radio defaultChecked={true} value='CNPJ'>CNPJ</Radio>
                                    <Radio value='CPF'>CPF</Radio>
                            </Field>
                        </Col>
                    </Row>

                    {/* Segunda Linha */}
                    <Row>
                        <Col style={{maxWidth: 300}}>
                            <Field 
                                accepter={MaskedInput}
                                name="documento"
                                error={formError.documento}
                                label="CNPJ/CPF"
                                showMask={true}
                                style={{width: '100%'}}
                                mask={tipoDocumento === 'CNPJ' ? [
                                    /\d/, /\d/, '.', 
                                    /\d/, /\d/, /\d/, '.', 
                                    /\d/, /\d/, /\d/, '/', 
                                    /\d/, /\d/, /\d/, /\d/, '-', 
                                    /\d/, /\d/
                                ]
                                :
                                [
                                    /\d/, /\d/, /\d/, '.', 
                                    /\d/, /\d/, /\d/, '.', 
                                    /\d/, /\d/, /\d/, '-', 
                                    /\d/, /\d/
                                ]
                                }  
                            />
                        </Col>
                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Razão Social / Nome"
                                name="razaoNome"
                                style={{width: '100%'}}
                                error={formError.razaoNome}
                            />
                        </Col>
                    </Row>

                    {/*Terceira Linha */}           
                    <Row>
                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Nicho de mercado"
                                name="nicho"
                                accepter={InputPicker}
                                data={nichos}
                                style={{width: '100%'}}
                                onSelect={(value: string) => {setNichoEscolhido(value); console.log(value)}}
                                error={formError.nicho}
                            />
                        </Col>

                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Atividade que se enquadra"
                                name="atividade"
                                accepter={TagPicker}
                                data={
                                    nichoEscolhido === 'Turismo Aquático' ? atividadesAquaticas : (
                                        nichoEscolhido === 'Turismo Aéreo' ? atividadesAereas : (
                                            nichoEscolhido === 'Turismo Terrestre' ? atividadesTerrestres : (
                                                nichoEscolhido === 'Hospedagem' ? atividadesHospedagem : (
                                                    nichoEscolhido === 'Atrativos Turísticos' ? atividadesTuristicas : (
                                                        nichoEscolhido === 'Hospedagem' ? atividadesHospedagem : (
                                                            nichoEscolhido === 'Alimentício' ? atividadesAlimenticias : semNicho
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                }
                                style={{ width: 200 }}
                                error={formError.atividade}
                            />
                        </Col>
                    </Row>
                                
                    {/*Quarta Linha */}    
                    <Row>
                        <span style={{display: 'block', fontFamily: 'Poppins-Medium', marginBottom: '2rem', fontSize: '2.6rem'}}>
                            Contato
                        </span>
                        <Col style={{maxWidth: 300}}>
                            <Field 
                              label="CEP"  
                              name='cep'
                              style={{width: '100%'}}
                              accepter={MaskedInput}
                              error={formError.cep}
                              onChange={(value: string) => setCEP(value)}
                              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                            />
                        </Col>

                        <Col style={{maxWidth: 300}}>
                            <Field 
                              label="País"  
                              name='pais'
                              value={'BR'}
                              style={{width: '100%'}}
                              data={paises}
                              accepter={InputPicker}
                              error={formError.pais}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Telefone (Whatsapp)"
                                name="telefone"
                                accepter={MaskedInput}
                                mask={[/\d/,/\d/,' ',/\d/,/\d/,/\d/,/\d/,/\d/,"-", /\d/,/\d/,/\d/,/\d/,]}
                                placeholder="DD 00000-0000"
                                style={{width: '100%'}}
                                error={formError.telefone}
                            /> 
                        </Col>

                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="E-mail"
                                name="email"
                                style={{width: '100%'}}
                                error={formError.email}
                            /> 
                        </Col>
                    </Row>
                    
                    {/*Sexta Linha */}
                    <Row>
                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Endereço"
                                name="endereco"
                                style={{width: '100%'}}
                                error={formError.endereco}
                            /> 
                        </Col>

                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Número"
                                name="numero"
                                style={{width: '100%'}}
                                error={formError.numero}
                            /> 
                        </Col>
                    </Row>

                    {
                        nichoEscolhido === 'Hospedagem' && (
                            <Row>
                                <span style={{display: 'block', fontFamily: 'Poppins-Medium', marginBottom: '2rem', fontSize: '2.6rem'}}>
                                    Detalhes da acomodação
                                </span>
                                <Col>
                                    <Field 
                                        label="Capacidade de Hospedes"
                                        name="qtdeMaxHospedes"
                                        error={formError.qtdeMaxHospedes}
                                    />
                                </Col>

                                <Col>
                                    <Field 
                                        label="Quantidade de quartos"
                                        name="qtdeQuartos"
                                        error={formError.qtdeQuartos}
                                    />
                                </Col>
                            </Row>
                        )
                    }

                    {
                        nichoEscolhido === 'Atrativos Turísticos' && (
                            <Row>
                                <span style={{display: 'block', fontFamily: 'Poppins-Medium', marginBottom: '2rem', fontSize: '2.6rem'}}>
                                    Capacidade do espaço
                                </span>
                                <Col>
                                    <Field 
                                        label="Capacidade de pessoas"
                                        name="qtdeMaxPessoasEspaco"
                                        error={formError.qtdeMaxPessoasEspaco}
                                    />
                                </Col>

                                <Col>
                                    <Field 
                                        label="Quantidade de cachoeiras"
                                        name="qtdeCachoeiras"
                                        error={formError.qtdeCachoeiras}
                                    />
                                </Col>
                            </Row>
                        )
                    }

                    <Row>
                        <span style={{display: 'block', fontFamily: 'Poppins-Medium', marginBottom: '2rem', fontSize: '2.6rem'}}>
                            Valores
                        </span>
                        <Col style={{maxWidth: 300}}>
                            <Field 
                                label="Valor médio cobrado por pessoa"
                                name="valorMedio"
                                accepter={InputNumber}
                                formatter={toThousands}
                                placeholder=""
                                style={{width: '100%'}}
                                error={formError.valorMedio}
                            /> 
                        </Col>
                    </Row>


                    <Form.Group>
                        <Button appearance="primary" onClick={handleSubmit}>
                            Cadastrar
                        </Button>
                    </Form.Group>

                </Grid>
            </Form>
        </>
    )
}