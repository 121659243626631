import { useState, useEffect } from "react"
import graph1 from "../../../assets/image/garfico 1.svg"
import graph2 from "../../../assets/image/garfico 2.svg"
import graph3 from "../../../assets/image/garfico 3.svg"
import "./card.css"

interface CardProps {
  title: string;
  subtitle1: string;
  subtitle2: string;
  subtitle3?: string;
  var1: number;
  var2: number;
  var3: number;
  var4?: number;
  taxa: string;
  hasImg: boolean;
  hasUnderline: boolean;
}

export const Card = ({title, subtitle1, subtitle2, subtitle3, var1, var2, var3, var4, taxa, hasImg, hasUnderline}: CardProps) => {
    return (
      <div className="deposit-card" style={{display: 'flex', justifyContent: 'space-evenly', width: '33%', alignItems: 'center', position: 'relative', borderRadius: '10px', overflow: 'hidden'}}>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <div>
            <div className="deposit-header">
              <h3 style={{color: '#8094AE', fontSize: '1.6rem'}}>{title}</h3>
            </div>
            <div className="deposit-total">
              <span className="amount" style={{color: '#364A63'}}>{var1}</span>
              <span className="amount" style={{color: '#526484', marginLeft: '1rem'}}>Pessoas</span>
              {
                taxa.startsWith('-') ? 
                (
                  <span className="percentage decrease" style={{marginLeft: '1rem'}}>
                    ↓ {taxa}%
                  </span>
                )
                :
                (
                  <span className="percentage increase" style={{marginLeft: '1rem'}}>
                    ↑ {taxa}%
                  </span>
                )
              }
            </div>
            {
              subtitle1 && (
                <>
                  <div className="deposit-breakdown">
                    <div>
                      <span className="label">{subtitle1}</span>
                      <br/>
                      <span className="value" style={{color: '#364A63'}}>{var2} <span style={{color: '#526484'}}>Pessoas</span></span>
                    </div>
                    <div>
                      <span className="label">{subtitle2}</span>
                      <br/>
                      <span className="value" style={{color: '#364A63'}}>{var3} <span style={{color: '#526484'}}>Pessoas</span></span>
                    </div>
                    {
                      subtitle3 && (
                        <>
                          <div>
                            <span className="label">{subtitle3}</span>
                            <br/>
                            <span className="value" style={{color: '#364A63'}}>{var4} <span style={{color: '#526484'}}>Pessoas</span></span>
                          </div>
                        </>
                      )
                    }
                  </div>
                </>
              )
            }
            
          </div>
          {
            hasImg && (
              <>
                <div style={{width: '45%', display: 'flex', alignItems: 'end', justifyContent: 'end'}}>
                  <img style={{width: '70%'}} src={graph1} alt="Gráfico"/>
                </div>
              </>
            )
          }
        </div>
        {
          hasUnderline && (
            <div style={{backgroundColor: '#1EE0AC', width:'100%', height: '1rem', position: 'absolute', bottom: '0'}} />
          )
        }
      </div>
  );
}