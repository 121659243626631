import React, { useState, useEffect } from "react";
import api from "../../../Services/axios";
import { formatDistanceToNow, isToday } from 'date-fns';
import { ptBR } from "date-fns/locale";
import { FaClock } from 'react-icons/fa';
import { Button, Modal, Placeholder } from "rsuite";
import { useNavigate } from "react-router-dom";
import PageNextIcon from '@rsuite/icons/PageNext';
import { IconButton } from 'rsuite';
import { Icon } from '@rsuite/icons';
import "../../../css/controleNautico.css";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../Auth/useAuthContext";

interface DadosNauticos {
    id: number;
    data_inicio: string;
    passageiros:string;
    ativa: number;
    Veiculo?: {
        id: number;
        nome:string;
    }
    User: {
        nome: string;
        Colaborador: {
            nome: string;
        }
    }
    Empresa: {
        id: number;
        rasao_social: string;
    }
}

const notifyVeiculoAlterado = () => toast.success('Veículo alterado com sucesso!', {
    position: "top-right", 
    autoClose: 3000,       
    hideProgressBar: false, 
    closeOnClick: true,    
    pauseOnHover: true,    
    draggable: true,       
    progress: undefined,   
});

const notifyColaboradorAlterado = () => toast.success('Condutor alterado com sucesso!', {
    position: "top-right", 
    autoClose: 3000,       
    hideProgressBar: false, 
    closeOnClick: true,    
    pauseOnHover: true,    
    draggable: true,       
    progress: undefined,   
});

const notifyErroAoAlterar = () => toast.error('Ocorreu um erro!', {
    position: "top-right", 
    autoClose: 3000,       
    hideProgressBar: false, 
    closeOnClick: true,    
    pauseOnHover: true,    
    draggable: true,       
    progress: undefined,   
});

const ControleNautico: React.FC = () => {
    const { user } = useAuthContext();

    const [isLoading, setIsLoading] = useState<Boolean>(false);
    const [dadosNauticos, setDadosNauticos] = useState<DadosNauticos[]>([]);
    const [totalPassageiros, setTotalPassageiros] = useState<number>(0);
    const [passageirosHoje, setPassageirosHoje] = useState<number>(0);
    const [empresaSelecionada, setEmpresaSelecionada] = useState<number>();
    const [colaboradoresList, setColaboradoresList] = useState<any>([]);
    const [veiculosList, setVeiculosList] = useState<any>([]);
    const [atividadeSelecionada, setAtividadeSelecionada] = useState<number>(0);
    const [openTrocaColaborador, setOpenTrocaColaborador] = useState(false);
    const [openTrocaVeiculo, setOpenTrocaVeiculo] = useState(false);
    const [flagTroca, setFlagTroca] = useState(false);

    async function trocaColaboradorEFechaModal(condutor_user_id: number, atividade_id: number){
        let response = await api.patch(`/atividade/${atividade_id}/troca-condutor/${condutor_user_id}`, { headers: {"Authorization" : "Bearer " + localStorage.getItem("accessToken")} })
        setEmpresaSelecionada(0);
        setAtividadeSelecionada(0);

        if(response.status === 200){
            setOpenTrocaColaborador(false);
            setFlagTroca(!flagTroca);
            notifyColaboradorAlterado();
        }else{
            setFlagTroca(!flagTroca)
            notifyErroAoAlterar();
        }
    }

    async function trocaVeiculoEFechaModal(veiculo_id: number, atividade_id: number){
        let response = await api.patch(`/atividade/${atividade_id}/troca-veiculo/${veiculo_id}`, 
            { headers: {"Authorization" : "Bearer " + localStorage.getItem("accessToken")} })
        setEmpresaSelecionada(0);
        setAtividadeSelecionada(0);

        if(response.status === 200){
            setOpenTrocaVeiculo(false);
            notifyVeiculoAlterado();
            setFlagTroca(!flagTroca);
        }else{
            setFlagTroca(!flagTroca);
            notifyErroAoAlterar();
        }
    }

    const handleOpenTrocaColaborador = (e: any, empresa_id: number, atividade_id: number) => {
        setEmpresaSelecionada(empresa_id);
        setAtividadeSelecionada(atividade_id);

        setOpenTrocaColaborador(true);
    };

    const handleCloseTrocaColaborador = () => setOpenTrocaColaborador(false);
    const handleCloseTrocaVeiculo = () => setOpenTrocaVeiculo(false);

    const handleOpenTrocaVeiculo = (e:any, empresa_id: number, atividade_id: number) => {
        setEmpresaSelecionada(empresa_id);
        setAtividadeSelecionada(atividade_id);
        setOpenTrocaVeiculo(true);
    }

    async function handleEncerrarAtividade(atividade_id: number){
        let response = await api.patch(`/atividade/${atividade_id}/encerrar`, 
            { headers: {"Authorization" : "Bearer " + localStorage.getItem("accessToken")} })

        if(response.status === 200){
            setFlagTroca(!flagTroca);
        }
    }


    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try{
                let passageiros = 0;

                setIsLoading(true);
                const {data: nauticos} = await api.get("/dashboard/dados-nauticos", 
                    {headers: {"Authorization" : "Bearer " + localStorage.getItem("accessToken")}});
                setIsLoading(false);
                setDadosNauticos(nauticos);

                nauticos.map((item: DadosNauticos) => 
                    passageiros += JSON.parse(item.passageiros).length
                )

                setTotalPassageiros(passageiros);
            }catch(error){
                console.log(error);
            }
        }

        fetchData();
    }, [flagTroca])

    useEffect(() => {
        let count = 0;

        const atividadesHoje = dadosNauticos.filter(item => {
            return (isToday(new Date(item.data_inicio)));
        })

        atividadesHoje.map(item => {
            count += JSON.parse(item.passageiros).length;
            return 0;
        })

        setPassageirosHoje(count)
    }, [dadosNauticos])

    useEffect(() => {
        const fetchColaboradores = async () => {
            let colaboradores = await api.get(`/empresa/${empresaSelecionada}/colaboradores`, { headers: {"Authorization" : "Bearer " + localStorage.getItem("accessToken")} });
            setColaboradoresList(colaboradores.data);
        }

        const fetchVeiculos = async () => {
            let veiculos = await api.get('/veiculo', { headers: {"Authorization" : "Bearer " + localStorage.getItem("accessToken")} });
            setVeiculosList(veiculos.data);
        }

        fetchColaboradores();
        fetchVeiculos();
    }, [empresaSelecionada])

    if(isLoading) {
        return <Placeholder.Graph />
    }

    return (
        <>
            <div>
                <div 
                    style={{
                        borderRadius: '10px', 
                        border: '1px solid rgba(72, 94, 144, 0.16)', 
                        padding: '2rem',
                    }}>

                    <div className="" style={{marginBottom: '3rem'}}>
                        <div className="" style={{display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'space-between'}}>
                            <div style={{marginBottom: '1rem'}}>
                                <h6 className="" style={{fontFamily: 'Poppins-Light'}}>Atividades Recentes</h6>
                            </div>
                            <div className="informacoesAtividadesContainer">
                                <p className="" style={{fontFamily: 'Poppins-Medium', fontSize: '12px', color: 'rgb(82, 100, 132)'}}>
                                    Última atividade: 
                                    <span style={{marginLeft: '5px', fontFamily: 'Poppins-Medium', fontSize: '12px', color: 'rgb(82, 100, 132)'}}>
                                        {dadosNauticos[0]?.data_inicio ? formatDistanceToNow(new Date(dadosNauticos[0]?.data_inicio), {locale: ptBR}):("")} atrás
                                    </span>
                                </p>
                                <span className="totalPassageiros" style={{fontFamily: 'Poppins-Medium', fontSize: '12px', color: 'rgb(82, 100, 132)'}}>
                                    Total de passageiros: {totalPassageiros}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="" style={{overflow: 'scroll', height: '50vh'}}>
                        <ul>
                        {
                            dadosNauticos && dadosNauticos.map((item, index) => (
                                <>
                                    <li key={index} className="activity-item">
                                        <div className="activity-icon bg-primary-light tx-primary">
                                            <i data-feather="clock"><FaClock/></i>
                                        </div>
                                        <div className="activity-body">
                                            <p className="mg-b-2 tituloInfoNautica">
                                                Marinheiro: <strong className="infoNautica">{item.User.Colaborador.nome}</strong>
                                                {
                                                    (user?.segmento === 'Empresa' || user?.segmento === 'Atendente') && (
                                                        <IconButton onClick={(e) => handleOpenTrocaColaborador(e, item.Empresa.id, item.id)}  
                                                        style={{
                                                            width: '32px', 
                                                            height: '32px', 
                                                            marginLeft: '1rem', 
                                                            backgroundColor: 'white', 
                                                            padding: 0
                                                        }} 
                                                        icon={<Icon as={PageNextIcon} style={{color: 'green', fontSize: 18}}/>}  />
                                                        )
                                                }
                                                <br />
                                                
                                            </p>
                                            <p className="mg-b-2 tituloInfoNautica">
                                            Empresa: 
                                                <a href="www.google.com" className="link-02 mg-l-5">
                                                    <strong className="infoNautica">{item.Empresa.rasao_social}</strong>
                                                </a>
                                            </p>
                                            <p className="mg-b-2 tituloInfoNautica">
                                                Veículo <strong className="infoNautica">{item?.Veiculo?.nome}</strong>
                                                {
                                                    (user?.segmento === 'Empresa' || user?.segmento === 'Atendente') && (
                                                        <IconButton onClick={(e) => handleOpenTrocaVeiculo(e, item.Empresa.id, item.id)}  
                                                        style={{width: '32px', height: '32px', marginLeft: '1rem', backgroundColor: 'white', padding: 0}} 
                                                        icon={<Icon as={PageNextIcon} style={{color: 'green', fontSize: 18}}/>}  
                                                        />
                                                    )
                                                }
                                                
                                            </p>
                                            <p className="mg-b-2 tituloInfoNautica">Passageiros: <strong className="infoNautica">{JSON.parse(item.passageiros).length}</strong> </p>
                                            <p>
                                                {item.ativa ? <small style={{color: 'green'}}>Em andamento</small> : <small style={{color: 'red'}}>Finalizada</small>}
                                            </p>
                                            <small className="tx-color-03">
                                                {item.data_inicio ? formatDistanceToNow(new Date(item.data_inicio), {locale: ptBR}) : "Sem informação"} atrás
                                            </small>
                                            <div className='controleNauticoButtons'>
                                                <Button style={{backgroundColor: '#354860', color: 'white', minWidth: '100px'}} onClick={(e) =>
                                                    navigate("/Empresa/Atividades/Empresa/TermoAtividades", {
                                                        state: { idAtividade: item.id },
                                                    })
                                                }>Baixar Termo</Button>

                                                <Button style={{backgroundColor: '#01b8bc', color: 'white', minWidth: '100px'}} onClick={(e) =>
                                                    navigate("/Empresa/Atividades/Empresa/Visitantes", {
                                                        state: { 
                                                            data: {
                                                                codigo: String(Math.random),
                                                                id: item.id,
                                                                titulo: '',
                                                                hora: '',
                                                                data_inicio: item.data_inicio,
                                                                empresa_rasao_social: item.Empresa.rasao_social,
                                                                passageiros: JSON.parse(item.passageiros),
                                                                ativa: item.ativa,
                                                                veiculo_id: item.Veiculo?.id 
                                                            },
                                                        },
                                                    })
                                                }>
                                                    Analisar
                                                </Button>
                                                <Button 
                                                    style={{ 
                                                        backgroundColor: '#eb5454', 
                                                        color: 'white', 
                                                        minWidth: '100px' 
                                                    }}
                                                    onClick={(e) => handleEncerrarAtividade(item.id)}
                                                    >
                                                    Encerrar
                                                </Button>
                                            </div>
                                        </div>
                                    </li>
                                </>
                            ))
                        }
                        </ul>
                    </div>
                </div>

                <div 
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                        padding: '3rem',
                        border: '1px solid rgba(72, 94, 144, 0.16)',
                        marginTop: '10px',
                        borderRadius: '10px',
                        position: 'relative',
                        overflow: 'hidden',
                    }}>
                        
                    <div 
                        style={{
                            position: 'absolute', 
                            bottom: '0', 
                            left: '0',
                            backgroundColor: '#364A63',
                            height: '20px', 
                            width: '100%', 
                        }}/>

                    <h6 style={{color: '#8094AE'}}>Total de atividades diárias</h6>

                    <div style={{display: 'flex', gap: '1rem'}}>
                        <span style={{color: 'rgb(54, 74, 99)', fontFamily: 'Poppins-Light', fontSize: '2.5rem' }}>
                            { dadosNauticos.filter(item => isToday(new Date(item.data_inicio))).length} 
                        <span style={{color: '#526484', fontFamily: 'Poppins-Light' }}> Atividades</span></span>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span className='labelTitleBottom'>EM ANDAMENTO</span>
                            <span className='labelTitleBottom'>{ dadosNauticos.filter(item => item.ativa === 1).length } 
                                <span className="labelBottom"> atividades</span>
                            </span>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span className='labelTitleBottom'>CLIENTES DO DIA</span>
                            <span className="labelBottom">{ passageirosHoje } <span style={{color: '#526484', fontFamily: 'Poppins-Light'}}> pessoas</span></span>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', marginBottom: '2rem'}}>
                            <span className='labelTitleBottom'>CLIENTES ATENDIDOS</span>
                            <span  className="labelBottom">{ totalPassageiros } <span style={{color: '#526484', fontFamily: 'Poppins-Light'}}> pessoas</span></span>
                        </div>
                    </div>

                   
                </div>

                <Modal backdrop={'static'} keyboard={false} open={openTrocaColaborador} onClose={handleCloseTrocaColaborador}>
                    <Modal.Header>
                    <Modal.Title>Trocar Colaborador</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                        {
                            colaboradoresList.length > 0 && colaboradoresList.map((item:any, index:number) => (
                                    <div key={index} 
                                        style={{
                                            display: 'flex', 
                                            flexDirection: 'column', 
                                            backgroundColor: '#f4f4f5',
                                            borderRadius: '10px',
                                            padding: '2rem'
                                        }}>

                                        <div style={{marginBottom: '2rem'}}>
                                            <span style={{color: '#5c6c7f', fontFamily: 'Poppins-Medium'}}>Condutor: </span><br/>
                                            <span style={{color: '#1b2e4b', fontFamily: 'Poppins-Medium'}}>{item.nome}</span>
                                        </div>
                                        
                                        {
                                            item.habilitacao_marinheiro !== 'N' && (
                                                <div style={{marginBottom: '2rem'}}> 
                                                    <span style={{color: '#5c6c7f', fontFamily: 'Poppins-Medium'}}>Habilitacao marinheiro(a): </span><br/>
                                                    <span style={{color: '#1b2e4b', fontFamily: 'Poppins-Medium'}}>{item.habilitacao_marinheiro}</span>
                                                </div>
                                            )
                                        }
                                        
                                        {
                                            item.habilitacao !== 'N' && (
                                                <div style={{marginBottom: '2rem'}}>
                                                    <span style={{color: '#5c6c7f', fontFamily: 'Poppins-Medium'}}>Habilitacao: </span><br/>
                                                    <span style={{color: '#1b2e4b', fontFamily: 'Poppins-Medium'}}>{item.habilitacao}</span>
                                                </div>
                                            )
                                        }

                                        <div style={{width: '100%', display: 'flex', justifyContent: 'end'}}>
                                            <Button onClick={(e) => trocaColaboradorEFechaModal(item.User.id, atividadeSelecionada)} style={{backgroundColor: '#00b9bd', color: 'white'}}>Adicionar</Button>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button style={{marginTop: '2rem'}} onClick={handleCloseTrocaColaborador} appearance="subtle">
                        Cancelar
                    </Button>
                    </Modal.Footer>
                </Modal>
                
                <Modal backdrop={'static'} keyboard={false} open={openTrocaVeiculo} onClose={handleCloseTrocaVeiculo}>
                    <Modal.Header>
                    <Modal.Title>Trocar Veículo</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '2rem', padding: '3rem'}}>
                        {
                            veiculosList.length > 0 && veiculosList.map((item:any, index:number) => (
                                    <div key={index} 
                                        style={{
                                            display: 'flex', 
                                            flexDirection: 'column', 
                                            backgroundColor: '#f4f4f5',
                                            borderRadius: '10px',
                                            padding: '5rem',
                                        }}>

                                        <div style={{marginBottom: '2rem'}}>
                                            <span style={{color: '#5c6c7f', fontFamily: 'Poppins-Medium'}}>Veiculo: </span><br/>
                                            <span style={{color: '#1b2e4b', fontFamily: 'Poppins-Medium'}}>{item.nome}</span>
                                        </div>

                                        <div style={{marginBottom: '2rem'}}>
                                            <span style={{color: '#5c6c7f', fontFamily: 'Poppins-Medium'}}>Capacidade: </span><br/>
                                            <span style={{color: '#1b2e4b', fontFamily: 'Poppins-Medium'}}>{item.capacidade}</span>
                                        </div>

                                        <div style={{width: '100%', display: 'flex', justifyContent: 'end'}}>
                                            <Button onClick={(e) => trocaVeiculoEFechaModal(item.id, atividadeSelecionada)} style={{backgroundColor: '#00b9bd', color: 'white'}}>Adicionar</Button>
                                        </div>
                                    </div>
                                )
                            )
                        }
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button style={{marginTop: '2rem'}} onClick={handleCloseTrocaVeiculo} appearance="subtle">
                        Cancelar
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}


export default ControleNautico;